import p1 from "../asset/p1.jpeg";
import p2 from "../asset/p2.jpeg";
import p3 from "../asset/p3.jpeg";
import p4 from "../asset/tree1.jpeg";
import holi from "../asset/holi.jpg";
import holi1 from "../asset/holi1.jpg";

import apple from "../asset/apple.jpg";
import coke from "../asset/coke.png";

import playStore from "../asset/playStore.png";
import appStore from "../asset/appStore.png";
import railtel from "../asset/railtel.png";
import logo3i from "../asset/3ilogo.png";
import flag from "../asset/india_flag.png";

//Page1 Ads CP_Landing Page
import imgAd_62cf573e from "../asset/ad/3i_62cf573e.png";
import adVed_72622a54 from "../asset/ad/3i_72622a54.mp4";
import imgAd_4f6b9a80 from "../asset/ad/3i_4f6b9a80.png";
//page2 Ads CP_Enter Mobile Number
import imgAd_15c867a8 from "../asset/ad/3i_15c867a8.png";
import adVed_05651ac2 from "../asset/ad/3i_05651ac2.mp4";
import imgAd_05651ac2 from "../asset/ad/3i_05651ac2.png";
import imgAd_c1a27249 from "../asset/ad/3i_c1a27249.png";
//page3 Ads CP_Enter OTP
import imgAd_8cc13612 from "../asset/ad/3i_8cc13612.png";
import adVed_5794f1a5 from "../asset/ad/3i_5794f1a5.mp4";
import imgAd_5794f1a5 from "../asset/ad/3i_5794f1a5.png";
import imgAd_5fc6e7ea from "../asset/ad/3i_5fc6e7ea.png";
//page4 Ads CP_Select Paid or Free
import imgAd_fbc60684 from "../asset/ad/3i_fbc60684.png";
import adVed_2093c133 from "../asset/ad/3i_2093c133.mp4";
import imgAd_2093c133 from "../asset/ad/3i_2093c133.png";
import imgAd_21779b57 from "../asset/ad/3i_21779b57.png";
//page5 Ads CP_Interstitial which is not used now
import adVed_9e2895af from "../asset/ad/3i_9e2895af.mp4";
import imgAd_9e2895af from "../asset/ad/3i_9e2895af.png";
import imgAd_df333f3c from "../asset/ad/3i_df333f3c.png";
import imgAd_a8340faa from "../asset/ad/3i_a8340faa.png";
import imgAd_5670abc1 from "../asset/ad/3i_5670abc1.png";

//page6 Ads CP_Connected
import imgAd_6b791b15 from "../asset/ad/3i_6b791b15.png";
import adVed_b99a9089 from "../asset/ad/3i_b99a9089.mp4";
import imgAd_b99a9089 from "../asset/ad/3i_b99a9089.png";
import imgAd_af59cbb3 from "../asset/ad/3i_af59cbb3.png";

//page7 Ads CP_Paid Wifi
import imgAd_1c7e2b83 from "../asset/ad/3i_1c7e2b83.png";
import adVed_ce9da01f from "../asset/ad/3i_ce9da01f.mp4";
import imgAd_36509a09 from "../asset/ad/3i_36509a09.png";


import ads from "../asset/ads.png";
import ad2 from "../asset/ad2.png";
import first_ad from "../asset/first_img.jpg";
import puma_ad from "../asset/puma_ad.png";

import sam_ad from "../asset/sam_ad.png";
import home_page_v_ad from "../asset/home_page_v_ad.png";





// import tata from "../asset/tata.mp4";
import suc from "../asset/suc.png";
import sec_ad from "../asset/sec_ad.png";
import third_ad from "../asset/third_ad.png";
import drop from "../asset/drop.png";
import message_ad from "../asset/message_ad.jpg";
import Railtel_NuReBharat from "../asset/Railtel_NuReBharat.png";
import RailtelNuReBharat from "../asset/RailtelNuReBharat.jpg";
// import railtelLogo from "../asset/railtel.jpg";
// import nuReBharat from "../asset/nuReBharat.jpg";
import railtelLogo from "../asset/railtelLogo.jpg";
import nuReBharat from "../asset/nuReBharat.png";
import failcross from "../asset/fail.png";
import videoAdd from "../asset/trailer_hd.mp4";
import chandrayaan from "../asset/chandrayaan.jpeg";
import BPCL_LOGO from "../asset/BPCL_LOGO.jpg";

const Imgs = {
  ads3i: {
    imgAd_62cf573e,
    adVed_72622a54,
    imgAd_4f6b9a80,
    imgAd_15c867a8,
    adVed_05651ac2,
    imgAd_c1a27249,
    imgAd_05651ac2,
    //page3 Ads CP_Enter OTP
    imgAd_8cc13612,
    adVed_5794f1a5,
    imgAd_5fc6e7ea ,
    imgAd_5794f1a5,
    //page4 Ads CP_Select Paid or Free
    imgAd_fbc60684 ,
    adVed_2093c133,
    imgAd_21779b57,
    imgAd_2093c133,
    //page5 Ads CP_Interstitial which is not used now
    adVed_9e2895af,
    imgAd_df333f3c,
    imgAd_a8340faa,
    imgAd_5670abc1,
    imgAd_9e2895af,
    

    //page6 Ads CP_Connected
    imgAd_6b791b15,
    adVed_b99a9089,
    imgAd_af59cbb3,
    imgAd_b99a9089,

    //page7 Ads CP_Paid Wifi
    imgAd_1c7e2b83,
    adVed_ce9da01f,
    imgAd_36509a09
  },
  flag,
  drop,
  p1,
  p2,
  videoAdd,
  // tata,
  failcross,
  p3,
  railtelLogo,
  nuReBharat,
  BPCL_LOGO,
  sec_ad,
  sam_ad,
  home_page_v_ad,
  third_ad,
  p4,
  holi,
  ads,
  apple,
  coke,
  ad2,
  playStore,
  message_ad,
  appStore,
  suc,
  railtel,
  logo3i,
  holi1,
  first_ad,
  puma_ad,
  Railtel_NuReBharat,
  RailtelNuReBharat,
  chandrayaan
};

export default Imgs;
