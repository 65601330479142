import React, {lazy, useContext, useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import { localhostURL } from "../common/baseUrl";
import axios from "axios";
import moment from 'moment';
import { Button } from "react-bootstrap";
const TrackApi = () => {
  const dateFormat=moment(new Date()).format("YYYY-MM-DD");
  console.log("Date-----",dateFormat);
  const [tableData, setTableData] = useState([]);
  const [datefield, setDateField] = useState(dateFormat);
const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'createdAt', headerName: 'CREATED AT', width: 180 },
    { field: 'param1', headerName: 'PARAM1', width: 180 },
    { field: 'param2', headerName: 'PARAM2', width: 180 },
    { field: 'param3', headerName: 'PARAM3', width: 180 },
];

const loadData = () => {
  let data = JSON.stringify({
    "createdAt": datefield
  });
  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: localhostURL +'/api/v1/ext/apk',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };
  
  axios.request(config)
  .then((response) => {
    console.log(JSON.stringify(response.data));
    setTableData(response.data.response)
  })
  .catch((error) => {
    console.log(error);
  });
  
}
const changeData =() =>{
  loadData();
}
// useEffect(() => {
//   loadData();
// }, [])

  return (
    <><div>
   <input type="date"
   className="p-2"
   placeholder="YYYY-MM-DD"
   value={datefield}
   onChange={(e) => setDateField(e.target.value)}
   ></input>
   <Button className="p-2 m-2 btn btn-primary" type="button" onClick={() => changeData()}>Search</Button>
    </div>
    <div className="track_api" style={{ height: 500, width: '120%', marginTop: '50px' }}>
        <DataGrid rows={tableData} columns={columns}
          pageSize={5} />
      </div></>
  );
};

export default TrackApi;
