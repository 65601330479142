import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Imgs from '../common/imgImports';
import {InsAdSopt} from './ad3i'; 
const Carousel = ({adSopts,  locationPath, adSopts2=[]}) => {
  const settings = {
      dots: (adSopts.length > 1)?true: false,
      auto: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
  };
  // console.log("adSopts.length", adSopts, adSopts2)
  return (
    <div className='container1' >
    <Slider {...settings} autoplay={true}>
      {/* df333f3c df333f3c */}
      {adSopts && adSopts.length > 0 && adSopts.map((adSopt, key)=>{
        return (<>
        <InsAdSopt key={key} adSoptData={adSopt} locationPath={locationPath} adSopts2={adSopts2[key]}/>
       </>)       
      })}
      {/* {adSopts2 && adSopts2.length > 0 && adSopts2.map((adSopt)=>{
        return (<>
        <InsAdSopt key={adSopt?.SrNo} adSoptData={adSopt} locationPath={locationPath}/>
       </>)
      })} */}
       
    </Slider>
    </div>
  );
};

export default Carousel;
