import { localhostURL } from "./baseUrl";

export const userVisit = (pageInfo) => {
    const data = {
      ...pageInfo,
      session: sessionStorage.getItem("browserSession")
    }
    console.log('data call with', data)
    fetch(`${localhostURL}/api/v1/ext/pageVisit`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  export const setSessionFn = () => {
    if(!sessionStorage.getItem("browserSession")){
        sessionStorage.setItem("browserSession", new Date().getTime());
        console.log("new session created :::", sessionStorage.getItem("browserSession"))
      } else {
        console.log("already exist session :::", sessionStorage.getItem("browserSession"))
      }
  }