import React, { useState,lazy, useContext, useEffect } from "react";
import "../../src/main.css";
import { Button} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "video-react/dist/video-react.css";
import 'react-toastify/dist/ReactToastify.css';
import { BaseURL,localhostURL } from "../common/baseUrl";
import { AdContext,AdIdContext } from "../components/adManagerContext";
import {deviceDetect,mobileModel,isAndroid,OsTypes,osName, isIOS, isBrowser,isDesktop} from "react-device-detect";
import Logo from "./logoComponent"
import {InsAdSopt} from './ad3i'; 
import { randomSites,LandingUrls } from "../common/randomSites";
import { Linking } from "react-native";
import { userVisit, setSessionFn } from '../common/commanfn';

const LogoComponent = lazy(() => import('./logoComponent'));
const FooterComponent = lazy(() => import('./footer'));



const WifiComponent = () => {
  const { t } = useTranslation();
  const {addManager, setAddManager} = useContext(AdContext);
  const [ad1, setAd1] = useState(addManager?.find(itm => itm.ad_container=="CP_Select_WIFITop"));
  const [ad2, setAd2] = useState(addManager?.find(itm => itm.ad_container=="CP_Select_WIFICenter"));
  const [ad3, setAd3] = useState(addManager?.find(itm => itm.ad_container=="CP_Select_WIFIBottom"));
  // eslint-disable-next-line
  const {locationPath, bunConfig} = useContext(AdIdContext); 
  const configTimeout = bunConfig[0]?.CP_Select_WIFI;
  const [seconds, setSeconds] = useState(configTimeout)
	useEffect(() => {
		const interval = setInterval(() => {
			setSeconds(seconds=> seconds-1)
		}, 1000);
		if(seconds < 0){
			clearInterval(interval)
		}
	}, [configTimeout]);

  useEffect(() =>{
    setAd1(addManager?.find(itm => itm?.ad_container === "CP_Select_WIFITop"));
    setAd2(addManager?.find(itm => itm?.ad_container === "CP_Select_WIFICenter"));
    setAd3(addManager?.find(itm => itm?.ad_container === "CP_Select_WIFIBottom"));
  },[addManager])

  const navigate = useNavigate();
  const [disbale,setDisable] = useState(false);
  const [resMessage,setresMessage] = useState('')
  
  const number = JSON.parse(localStorage.getItem("phoneNumber"));
  const reqBody=JSON.parse(window.localStorage.getItem("reqBody"));
  const customerData= JSON.parse(localStorage.getItem("cusData"));
  
 
  const verifyFreeSub = async () => {
    const customerData= JSON.parse(localStorage.getItem("cusData"));
    if(number){
      reqBody.mobileno= number;
    }
   
    const postData={
      reqBody: reqBody,
      custdata: customerData
    };
    await fetch(
      `${localhostURL}/api/v1/radius/verifyFreeWIFI`,
      {
        method: "POST",
        body: JSON.stringify(postData),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setresMessage(data.response.success);
        if (data.response.success == true) {
          navigate("/activeFreewifiPlan");
        } else {
          alert("Free plan limit exhausted for the day.");
       
        }
      })
      .catch((err) => {
        console.log(err.messsage);
      });
  };
  

  const allpaidPlanList = async () => {
    if(number){
      reqBody.mobileno= number;
    }
    await fetch(
      `${localhostURL}/api/v1/radius/allPaidPlanList`,
      {
        method: "POST",
        body: JSON.stringify(reqBody),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        window.localStorage.setItem("listData",JSON.stringify(data.data));
        if (data.success == true) {
          
          navigate("/selectPaidOptions");
        } else {
          //alert(data.msg);
        }
      })
      .catch((err) => {
        console.log(err.messsage);
      });
  };
  const checkForPaidplan = async () => {
    if(number){
      reqBody.mobileno= number;
    }
    const postData = {
      reqBody: reqBody,
      custdata: customerData
    }
    await fetch(
      `${localhostURL}/api/v1/radius/isPaidSubsExistsCommFlow`,
      {
        method: "POST",
        body: JSON.stringify(postData),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success == true && data.msg=="List of coupons") {
          window.localStorage.setItem("listData",JSON.stringify(data.data));
          navigate("/selectPaidOptions");
        } else {
           //allpaidPlanList();
          navigate("/paidwifiConnected");
        }
      })
      .catch((err) => {
        console.log(err.messsage);
      });
  };
//   const openURL= () => {
//     window.open(LandingUrls, '_blank'); 
//  }
//  const linking =LandingUrls;
//    const openIntentURL = async (url) => {
//      try {
//        const supported = await Linking.canOpenURL(url);
   
//        if (supported) {
//          await Linking.openURL(url);
//        } else {
//          console.log(`Cannot open URL: ${url}`);
//        }
//      } catch (error) {
//        console.error('An error occurred while opening the URL:', error);
//      }
//    };
  const handleSubmit = () => {
    verifyFreeSub();
    //openURL();
    //openIntentURL(linking);
   
  };

  const navigateToPlanList = async(e) =>{
    //await allpaidPlanList();
    await checkForPaidplan();
  }
  
  useEffect(() => { 
    setSessionFn();
    userVisit({select_wifi: 1});
   },[]);

  return (
    <div className="header-main">
      <Logo adSoptData={ad1} locationPath={locationPath}/>
      <div className="video-ad">
      <InsAdSopt adSoptData={ad2} locationPath={locationPath}/>      
      </div>
      {resMessage &&
      <div className="Freewifi-validation" style={{color:'red',marginTop: '14px'}}>
        {t("description.noFreeWifi")}
        </div> }
      <div className="wifi-btns">
        {/* <Button
          variant="primary"
          className="wifi-paid"
          onClick={() => navigateToPlanList()}
          disabled={seconds > 0}
        >
          <span className="paid-wifi-main">{t("description.paidWifi")}</span>
          <div className="btm-text-btn">{t("description.paidSpeed")}</div>
          {seconds > 0  ?  <>
              <div className="spinner-grow"  role="status">...</div>
            <>{seconds}</>
          </> : null}
        </Button> */}
        <Button
          variant="primary"
          className="wifi-free"
          onClick={() => {
            handleSubmit();
          }}
          disabled={disbale}
        >
          <span className="paid-wifi-main">{t("description.freeWifi")}</span>
          <div className="btm-text-btn">{t("description.freeSpeed")}</div>
          {seconds > 0  ?  <>
              <div className="spinner-grow"  role="status">...</div>
            <>{seconds}</>
          </> : null}
        </Button>
      </div>
      {/* <div className="link-user"> */}
        {/* <a href="" className="links-a-user">Paid User Login here</a>{" | "} */}
        {/* <a href="/user" className="links-a-user">
          {t("description.userLogin")}
        </a>
      </div> */}

      <div className="bottom-ads2 mx-auto">
        {console.log("Location :", ad3)}
      <InsAdSopt adSoptData={ad3} locationPath={locationPath}/> 
      </div>

      {/* footer links  */}
      <div className="foot-div">
        <FooterComponent />
      </div>
    </div>
  );
};

export default WifiComponent;
