export const BaseURL = "http://43.205.172.192:3000";
//export const localhostURL = "http://43.204.3.125:9090";

 export const localhostURL = "https://cptg.piponet.in"; //"https://testcpt.piponet.in";
//export const localhostURL = "https://http://cpts.piponet.in";
export const billdeskURL = "https://pgi.billdesk.com/pgidsk/PGIMerchantPayment";
export const AAAURL = "https://dcg-stationwifi.railwire.co.in";

export const constants =
{
//googleAdId:"ca-pub-1558075804185455",
googleAdId_ext:"ca-pub-4391172542900075",
CP_Landing_Billboard_Banner:"9268377821",
slotId_2:"3822543489", 
slotIdVideo:"6563210361",
slotId_3:"6815790523",
slotId_4:"5423977064",//header
slotId_ext:"6815790523"
}











