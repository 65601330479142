import React, { useState,lazy } from "react";
import "../../src/main.css";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "video-react/dist/video-react.css";
import { localhostURL} from "../common/baseUrl";
const LogoComponent = lazy(() => import('./logoComponent'));
const FooterComponent = lazy(() => import('./footer'));

const AddEnquiry = () => {
  const { t } = useTranslation();
  const [mobileNumber, setMobileNumber] = useState("");
  const [comments,setComments] = useState("");
  const [userName,setUserName] = useState("");
  const [userEmail,setUserEmail] = useState("");
  const [hasError,setHasError]= useState(false);
  const navigate = useNavigate();
  const addEnquiryReqBody = {
    name: userName,
    email: userEmail,
    mobile_no: mobileNumber,
    country_code:"",
    comment: comments,
  };

  const addenquiryPost = async () => {
    await fetch(`${localhostURL}/api/v1/radius/addEnquiry`, {
      method: "POST",
      body: JSON.stringify(addEnquiryReqBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => response.json())
    .then((data) => {
      if(data.status == "OK"){
        navigate("/");
      }
    })
    .catch((err) => {
      console.log(err.messsage);
    });
  };

  const handleSubmit = (e) => {
    if(userName.length >= 1 && mobileNumber.length == 10){
      setHasError(false);
      addenquiryPost();
    
    }else{
      setHasError(true);
      
    }
  };

  return (
    <div className="header-main">
      <LogoComponent />
      <div className="back-head">
          <span
            className="back-btn"
          >
          </span>
          <span className="back-text">{t('description.AdEnquiry')}</span>
        </div>
      <div className="p-num1">
        <Form>
          <Form.Group className="first-name-text" controlId="formBasicPassword">
            <Form.Label className="label-form2">{t('description.usernamePlace1')}*</Form.Label>
            <Form.Control
              placeholder={t('description.usernamePlace1')}
              value={userName}
              type="text"
              onChange={(e) => {
                setUserName(e.target.value);
              }}
            />
            {hasError === true  && (
                          <div className="error-style">
                          {t('description.ValidateUser')}
                          </div>
                        )}
          </Form.Group>
          <Form.Group className="email-text12" controlId="formBasicEmail">
            <Form.Label className="label-form2">{t('description.email')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('description.email')}
              value={userEmail}
              onChange={(e) => {
                setUserEmail(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="email-text12" controlId="formBasicEmail">
            <Form.Label className="label-form2">{t('description.phonePlace')}*</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('description.phonePlace')}
              value={mobileNumber}
              onChange={(e) => {
                setMobileNumber(e.target.value);
              }}
            />
             {hasError == true  && (
                          <div className="error-style">
                            {t('description.validNumber')}
                          </div>
                        )}
             </Form.Group>
             <Form.Group className="email-text12" controlId="formBasicEmail">
            <Form.Label className="label-form2">{t('description.complaint1')}</Form.Label>
            <textarea
            className="commentsArea"
              type="text"
              placeholder={t('description.complaint1')}
              value={comments}
              onChange={(e) => {
                setComments(e.target.value);
              }}
            />
          </Form.Group>
        </Form>
        <Button
          className="enter-btn"
          onClick={() => {
            handleSubmit();
          }}
        >
         {t('description.submit')}
        </Button>
      </div>

        <p>{t('description.messageLine')}<a href="mailto:nbn.connect@nurebharatnetwork.com" target="_blank">{t('description.Mailto')}</a></p>
     
      {/* <div className="video-ad">
      <PrimaryAd></PrimaryAd>
      </div> */}
      <div style={{ marginTop: "70px" }}>
        <FooterComponent />
      </div>
    </div>
  );
};

export default AddEnquiry;
