export const LandingUrls= [
'https://www.hotstar.com/in?utm_source=affiliates_railtel&utm_medium=cpv&utm_campaign=cwc23_perf_Railtel_Screen6Redirect_cpv'
];
export const youtubeUrls = [
    'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1'
];
export const randomSites = [
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/privacy-policy/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/terms-of-use/',
'https://www.piponet.in/deleteaccount/',
'https://www.piponet.in/titanic-tourist-submarine-missing/',
'https://www.piponet.in/car-explosion-in-manipur/',
'https://www.piponet.in/englands-loss-mccullums-take/',
'https://www.piponet.in/nintendo-2023-lineup/',
'https://www.piponet.in/kylian-mbappe-transfer-rumors/',
'https://www.piponet.in/tesla-future-in-india/',
'https://www.piponet.in/sikkim-tourism-blocked/',
'https://www.piponet.in/pm-modi-deepens-indo-us-relations/',
'https://www.piponet.in/titanic-submarine-disastrous-implosion/',
'https://www.piponet.in/pm-modi-secures-development-for-india/',
'https://www.piponet.in/assam-facing-monsoon-challenges/',
'https://www.piponet.in/firm-lays-off-workforce-replacing-with-ai/',
'https://www.piponet.in/robbers-or-robin-hoods/',
'https://www.piponet.in/the-flash-wb-box-office-disaster/',
'https://www.piponet.in/monsoon-strikes-the-northwest/',
'https://www.piponet.in/where-is-putin/',
'https://www.piponet.in/when-nature-calls-at-30000-feet/',
'https://www.piponet.in/tragic-fire-roars-in-tripura/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/peace-through-fashion/',
'https://www.piponet.in/barbie-marketing-is-unique-marketing/',
'https://www.piponet.in/chadwick-boseman-gets-a-star/',
'https://www.piponet.in/italy-adopts-anti-semitism/',
'https://www.piponet.in/titan-sub-debris-recovered/',
'https://www.piponet.in/musk-vs-zuckerberg-battle-of-the-billionaires/',
'https://www.piponet.in/race-based-admissions-no-more/',
'https://www.piponet.in/gymshark-employee-prank/',
'https://www.piponet.in/among-us-gets-a-tv-show/',
'https://www.piponet.in/largest-cruise-on-earth-setting-sail/',
'https://www.piponet.in/robber-asks-out-victim/',
'https://www.piponet.in/scientists-volunteer-for-year-long-mars-simulation/',
'https://www.piponet.in/hug-stops-a-robbery/',
'https://www.piponet.in/90-effective-cancer-treatment-surfaces/',
'https://www.piponet.in/can-blocking-the-sun-solve-climate-change/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/microscopic-handbag-sells-for-63000/',
'https://www.piponet.in/squid-game-creator-receives-no-royalties/',
'https://www.piponet.in/soft-drinks-now-legally-unhealthy/',
'https://www.piponet.in/woman-who-couldnt-read-or-write-till-16-graduates-university/',
'https://www.piponet.in/deadly-roller-coaster-accident-averted/',
'https://www.piponet.in/superhero-window-cleaners/',
'https://www.piponet.in/hachiko-the-dog-turns-100/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/ranbir-kapoors-animal-deferred-to-december-11/',
'https://www.piponet.in/devgan-is-one-of-the-most-followed-star-kids-in-town/',
'https://www.piponet.in/sonam-kapoor-joins-natalie-portman-for-diors-autumn-winter-show/',
'https://www.piponet.in/blue-whales-endangered-no-more/',
'https://www.piponet.in/the-hilarious-grimace-shake-trend/',
'https://www.piponet.in/nfts-are-risky-an-example/',
'https://www.piponet.in/billionaire-recommends-dropping-out/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/colosseum-vandalized-by-tourist/',
'https://www.piponet.in/narnia-to-potentially-return/',
'https://www.piponet.in/lightning-strike-kills-cows/',
'https://www.piponet.in/threads-the-new-instagram/',
'https://www.piponet.in/time-moved-in-slow-motion/',
'https://www.piponet.in/cocaine-in-the-white-house/',
'https://www.piponet.in/trapped-upside-down-for-4-hours/',
'https://www.piponet.in/hottest-day-recorded/',
'https://www.piponet.in/celebration-or-environmental-hazard/',
'https://www.piponet.in/threads-the-new-twitter/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/vietnam-bans-barbie/',
'https://www.piponet.in/battle-of-the-billionaires/',
'https://www.piponet.in/__trashed/',
'https://www.piponet.in/mr-beast-on-distasteful-impersonation-prank/',
'https://www.piponet.in/titan-sub-company-suspends-operations/',
'https://www.piponet.in/tom-cruise-to-do-mission-impossible-till-80/',
'https://www.piponet.in/shah-rukh-khan-sets-the-mahaul-for-jawan/',
'https://www.piponet.in/kritis-looking-for-a-new-home/',
'https://www.piponet.in/harleen-sethi-inks-an-ode-to-her-punjabi-roots/',
'https://www.piponet.in/longest-journey-on-earth/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/tooth-regrowth-medicine-in-the-works/',
'https://www.piponet.in/threads-surges-in-user-base/',
'https://www.piponet.in/ancient-language-deciphered-by-ai/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/thumbs-up-emoji-legally-binding/',
'https://www.piponet.in/oppenheimer-has-zero-cgi/',
'https://www.piponet.in/star-lord-getting-solo-film/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/chat-checkouts-for-lonely-old-people/',
'https://www.piponet.in/saudi-arabia-takes-care-of-its-footballers/',
'https://www.piponet.in/six-killed-in-small-plane-crash-near-runway/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/ex-amazon-manager-gets-16-years-in-prison/',
'https://www.piponet.in/biggest-gps-drawing-made-to-propose/',
'https://www.piponet.in/can-you-be-friends-with-your-robber/',
'https://www.piponet.in/fda-requested-to-investigate-prime-energy/',
'https://www.piponet.in/jackie-chan-wont-pass-his-fortune-to-his-kids/',
'https://www.piponet.in/flash-expected-to-be-biggest-box-office-failure/',
'https://www.piponet.in/grammy-nominee-raja-kumaris-special-track-for-shah-rukh-khans-jawan/',
'https://www.piponet.in/chunni-babu-will-always-remain-close-to-my-heart-jackie-shroff/',
'https://www.piponet.in/rashmika-mandanna-exits-director-venky-kudumulas-next-with-nithiin/',
'https://www.piponet.in/zombie-apocalypse-on-netflix/',
'https://www.piponet.in/cancer-treatment-develops-further/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/life-on-venus/',
'https://www.piponet.in/jamie-foxx-is-healthy-and-happy/',
'https://www.piponet.in/robots-might-be-good-company/',
'https://www.piponet.in/the-real-cheeseburger/',
'https://www.piponet.in/microsoft-acquires-activision-blizzard/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/bank-of-america-fined-250-million/',
'https://www.piponet.in/gold-found-in-entrails-of-a-whale/',
'https://www.piponet.in/pewdiepie-and-marzia-welcome-their-son/',
'https://www.piponet.in/tom-holland-on-hollywood/',
'https://www.piponet.in/twitter-facing-500-million-lawsuit/',
'https://www.piponet.in/ceo-fires-90-of-support-staff/',
'https://www.piponet.in/meth-found-in-japanese-restaurant-in-florida/',
'https://www.piponet.in/hollywood-on-strike/',
'https://www.piponet.in/best-film-rdj-has-ever-been-in/',
'https://www.piponet.in/surgeons-reattach-boys-head-successfully/',
'https://www.piponet.in/taylor-swift-economy-booster/',
'https://www.piponet.in/isro-launches-chandrayaan-3/',
'https://www.piponet.in/nasa-confirms-5470-exoplanets-so-far/',
'https://www.piponet.in/barbenheimer/',
'https://www.piponet.in/protest-in-moderation/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/worlds-richest-beggar/',
'https://www.piponet.in/biden-administration-forgiving-student-debt/',
'https://www.piponet.in/ultra-white-paint-to-reduce-global-warming/',
'https://www.piponet.in/united-airlines-lifetime-pass/',
'https://www.piponet.in/earthquake-of-7-4-magnitude-strikes-alaska/',
'https://www.piponet.in/rare-twin-pandas-born-in-south-korea/',
'https://www.piponet.in/carlos-alcaraz-wins-wimbledon-final/',
'https://www.piponet.in/lab-grown-humans/',
'https://www.piponet.in/new-drug-to-slow-alzheimers/',
'https://www.piponet.in/india-and-uae-to-trade-in-rupees/',
'https://www.piponet.in/world-record-heat-in-death-valley/',
'https://www.piponet.in/meteorite-from-another-solar-system/',
'https://www.piponet.in/oppenheimer-debut-a-hit/',
'https://www.piponet.in/gta-6-cost-rockstar-over-2-billion/',
'https://www.piponet.in/netflix-add-6-million-subs-after-password-sharing-crackdown/',
'https://www.piponet.in/barbie-debut-a-hit/',
'https://www.piponet.in/takeshis-castle-is-back/',
'https://www.piponet.in/oppenheimer-a-story/',
'https://www.piponet.in/farmer-saves-a-starving-leopard/',
'https://www.piponet.in/cocaine-sharks/',
'https://www.piponet.in/dogs-rescues-abandoned-baby-from-the-trash/',
'https://www.piponet.in/depp-vs-heard-docuseries-in-the-works/',
'https://www.piponet.in/easter-bunny-chocolate-egg-thief/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/what-would-you-do-if-you-saw-a-celebrity-serving-you-at-your-everyday-restaurant/',
'https://www.piponet.in/barbie-director-refused-to-cut-this-scene-from-the-movie/',
'https://www.piponet.in/mexico-now-number-1-us-trade-partner/',
'https://www.piponet.in/matt-damons-preparation-for-oppenheimer/',
'https://www.piponet.in/twitter-changes-logo-to-x/',
'https://www.piponet.in/x-to-be-powered-by-ai/',
'https://www.piponet.in/skyscraper-sized-asteroid-flew-by-earth-and-no-one-noticed/',
'https://www.piponet.in/you-can-hear-the-nuke-while-watching-barbie/',
'https://www.piponet.in/mbappe-receives-1-1bn-offer-from-al-hilal/',
'https://www.piponet.in/the-rock-donates-huge-sum-to-sag-aftra/',
'https://www.piponet.in/paris-brings-back-swimming-in-river-seine/',
'https://www.piponet.in/importance-of-silencing-phones-during-movies/',
'https://www.piponet.in/homosexuality-frowned-upon-by-china/',
'https://www.piponet.in/airbag-jackets-for-the-elderly/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/potentially-habitable-earth-like-exoplanet-discovered/',
'https://www.piponet.in/alien-life-confirmed/',
'https://www.piponet.in/mbappe-rejects-astronomical-al-hilal-deal/',
'https://www.piponet.in/creative-parenting-punishments/',
'https://www.piponet.in/twitter-user-who-owned-the-handle-x-says-the-company-took-it-without-paying-him/',
'https://www.piponet.in/liam-hemsworth-replaces-henry-cavill-as-geralt-in-the-witcher/',
'https://www.piponet.in/global-warming-is-now-global-boiling/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/earths-hottest-month/',
'https://www.piponet.in/man-with-the-golden-arm/',
'https://www.piponet.in/x-to-only-offer-dark-mode/',
'https://www.piponet.in/gender-changes-banned-in-russia/',
'https://www.piponet.in/__trashed-4/',
'https://www.piponet.in/taylor-swift-fans-cause-seismic-activity/',
'https://www.piponet.in/ksi-and-logan-paul-to-fight-in-the-same-event-for-the-first-time-since-2019/',
'https://www.piponet.in/judge-accused-of-scrolling-on-her-phone-during-murder-trial-of-a-2-year-old/',
'https://www.piponet.in/usa-recovered-non-human-biologics/',
'https://www.piponet.in/worm-revived-after-46000-years/',
'https://www.piponet.in/__trashed-2/',
'https://www.piponet.in/how-climate-change-affects-us/',
'https://www.piponet.in/putin-forgives-23-billion-in-debt-owed-by-african-countries/',
'https://www.piponet.in/most-individual-swimming-titles-in-history/',
'https://www.piponet.in/gay-conversion-in-india/',
'https://www.piponet.in/barbie-album-gets-3-songs-in-official-top-5/',
'https://www.piponet.in/ksi-vs-tommy-fury-on-october-14th/',
'https://www.piponet.in/oceangate-co-founder-wants-to-send-1000-people-to-venus/',
'https://www.piponet.in/high-rise-daredevil-fell-to-death-in-hong-kong/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/beyond-the-spider-verse-no-longer-has-a-set-release-date/',
'https://www.piponet.in/pee-wee-herman-paul-reubens-dies-at-70/',
'https://www.piponet.in/mom-saves-child-from-cougar-attack/',
'https://www.piponet.in/study-shows-4-minutes-of-rigorous-activity-reduces-cancer-risk-in-non-exercisers/',
'https://www.piponet.in/first-player-to-wear-a-hijab-in-a-world-cup/',
'https://www.piponet.in/witness-the-spectacle-augusts-dual-supermoons-for-astronomy-enthusiasts/',
'https://www.piponet.in/nasas-voyager-2-mission-encounters-communication-setback/',
'https://www.piponet.in/ai-empowers-paralyzed-man-to-walk-again-in-remarkable-medical-breakthrough/',
'https://www.piponet.in/heartwarming-gesture-homeless-man-expresses-gratitude-for-help-received/',
'https://www.piponet.in/lizzo-faces-lawsuit-alleging-sexual-harassment/',
'https://www.piponet.in/mrbeast-burger-lawsuit-youtube-star-takes-legal-action-against-his-own-burger-company-over-quality-issues/',
'https://www.piponet.in/revolutionary-breakthrough-unveiling-the-holy-grail-cancer-drug/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/greta-gerwigs-barbie-makes-history-as-highest-grossing-film-ever-directed-by-a-woman/',
'https://www.piponet.in/former-boston-high-school-principal-faces-embezzlement-charges-for-allegedly-diverting-40000-to-fund-luxurious-trips/',
'https://www.piponet.in/unconventional-world-record-somalia-delegate-grabs-attention-with-slowest-100m-sprint/',
'https://www.piponet.in/unveiling-enigma-consortium-acquires-50000-acres-adjacent-to-california-air-force-base/',
'https://www.piponet.in/high-octane-theatrics-google-maps-car-stars-in-dazzling-car-chase-sequence/',
'https://www.piponet.in/from-homelessness-to-triumph-dwayne-the-rock-johnsons-inspiring-connection-with-mma-fighter-themba-gorimbo/',
'https://www.piponet.in/james-webb-space-telescope-unveils-captivating-cosmic-beauty-in-stunning-images/',
'https://www.piponet.in/lionsgate-completes-entertainment-one-acquisition-unveils-monopoly-movie-collaboration/',
'https://www.piponet.in/remembering-mark-margolis-the-enigmatic-hector-salamanca-of-breaking-bad/',
'https://www.piponet.in/from-advocate-to-accused-sound-of-freedom-contributor-faces-kidnapping-charges/',
'https://www.piponet.in/the-voyager-2-saga-restoring-communication-amidst-challenges/',
'https://www.piponet.in/illuminating-nighttime-safety-tarmac-linemarkings-revolutionary-glow-in-the-dark-road-markings/',
'https://www.piponet.in/beyond-bytes-mark-zuckerbergs-palate-pleasing-4000-calorie-diet/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/turning-trash-into-treasure-garbage-collectors-turn-waste-into-library-in-turkey/',
'https://www.piponet.in/mathea-allansmith-writing-history-at-92-with-guinness-world-record-marathon-triumph/',
'https://www.piponet.in/greta-gerwigs-barbie-makes-cinematic-history-by-crossing-1-billion-milestone/',
'https://www.piponet.in/unos-unprecedented-quest-become-the-chief-uno-player-and-play-your-way-to-earnings/',
'https://www.piponet.in/microplastics-invade-the-human-heart-startling-discoveries-from-beijing-anzhen-hospital/',
'https://www.piponet.in/tech-titans-clash-musks-enigmatic-response-ignites-zuckerberg-spat/',
'https://www.piponet.in/stunning-display-perseid-meteor-shower-dazzles-nature-enthusiasts-and-stargazers/',
'https://www.piponet.in/when-annoyance-matters-the-nancy-holten-story-and-switzerlands-citizenship-discourse/',
'https://www.piponet.in/neymar-jrs-blockbuster-move-to-al-hilal-footballs-paradigm-shift/',
'https://www.piponet.in/revolutionizing-space-cooling-u-s-air-force-collaborates-with-university-of-virginia-on-freeze-ray-breakthrough/',
'https://www.piponet.in/chinas-cinematic-landscape-transformed-meg-2-the-trench-reshapes-box-office-dynamics/',
'https://www.piponet.in/rainbow-swatch-watch-controversy-malaysias-lgbtq-accessory-ban-sparks-debate/',
'https://www.piponet.in/resilience-unveiled-a-13-year-olds-remarkable-recovery-from-a-grand-canyon-fall/',
'https://www.piponet.in/unveiling-the-enigma-scientists-discover-intriguing-new-undersea-creature-in-antarctic-depths/',
'https://www.piponet.in/volkswagens-dynamic-light-assist-illuminating-the-path-to-nighttime-safety/',
'https://www.piponet.in/revving-towards-an-electrifying-future-lamborghinis-sneak-peek-into-electric-mobility/',
'https://www.piponet.in/unveiling-vapings-dangers-sean-tobins-starting-transition-from-fitness-to-health-battle/',
'https://www.piponet.in/online-discourse-disrupted-elon-musks-controversial-removal-of-blocking-feature-on-x/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/gender-inclusion-debate-fide-bans-trans-people-from-elite-female-chess-competitio/',
'https://www.piponet.in/the-gambias-flavorful-victory-celebrating-culinary-diversity-at-waffest/',
'https://www.piponet.in/lanzador-by-lamborghini-a-revolutionary-electric-marvel-unveiled-at-monterey-car-week/',
'https://www.piponet.in/a-heartwarming-aviation-tale-father-daughter-duo-brian-and-becky-morgan-soar-to-inspire/',
'https://www.piponet.in/solidarity-in-crisis-japans-2-million-humanitarian-aid-package-for-wildfire-hit-hawaii/',
'https://www.piponet.in/lionel-messis-stunning-display-10-goals-in-7-games-for-inter-miami-in-2023-leagues-cup-final/',
'https://www.piponet.in/russias-lunar-pursuit-stumbles-luna-25-mission-faces-unforeseen-obstacles/',
'https://www.piponet.in/a-historic-triumph-spanish-womens-national-football-team-crowned-world-champions-for-the-first-time/',
'https://www.piponet.in/lionel-messi-clinches-league-cup-adds-new-milestone-to-his-remarkable-collection/',
'https://www.piponet.in/youtubes-multiview-feature-faces-backlash-balancing-innovation-and-practicality/',
'https://www.piponet.in/rolls-royce-unveils-new-droptail-roadster/',
'https://www.piponet.in/flash-has-the-biggest-financial-loss-for-a-superhero-film-ever/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/xbox-introduces-strike-system-for-toxic-users/',
'https://www.piponet.in/aliens-could-possibly-contact-earth-today/',
'https://www.piponet.in/carlsen-beats-praggnanandhaa-to-win-fide-world-cup/',
'https://www.piponet.in/chandrayaan-3-cost-lesser-than-interstellar/',
'https://www.piponet.in/brics-welcomes-6-of-the-worlds-9-leading-oil-producers-as-members/',
'https://www.piponet.in/wwe-superstar-bray-wyatt-passes-away-at-36/',
'https://www.piponet.in/chandrayaan-3-becomes-most-viewed-livestream-in-history/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/spanish-national-football-womens-team-demands-president-to-resign/',
'https://www.piponet.in/uk-reporter-demands-back-2-3-billion-pounds-given-to-india-as-financial-aid/',
'https://www.piponet.in/donald-trump-arrested-mugshot-released/',
'https://www.piponet.in/neeraj-chopra-wins-gold-for-india/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/iit-bombay-receives-160cr-anonymous-donation-from-alumnus/',
'https://www.piponet.in/fifa-suspends-spanish-football-president/',
'https://www.piponet.in/bob-barker-tv-show-host-passes-away-at-99/',
'https://www.piponet.in/india-curbs-exports-on-basmati-rice/',
'https://www.piponet.in/isro-now-aiming-for-the-sun/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/5-year-old-raises-17000-for-wildfire-victims/',
'https://www.piponet.in/indo-china-relations-to-improve/',
'https://www.piponet.in/linkedin-competitor-in-the-works/',
'https://www.piponet.in/anticipation-builds-for-india-pakistan-match/',
'https://www.piponet.in/china-maps-stake-claim-over-arunachal-pradesh-and-aksai-chin/',
'https://www.piponet.in/government-reduces-lpg-cylinder-price-by-rs-200-as-raksha-bandhan-gift/',
'https://www.piponet.in/tottenham-hotspur-knocked-out-by-fulham-in-huge-carabao-cup-upset/',
'https://www.piponet.in/saudi-arabia-revolutionizes-e-sports/',
'https://www.piponet.in/chandrayaan-3-finds-sulphur-on-the-moon-among-other-elements/',
'https://www.piponet.in/rare-blue-supermoon-to-illuminate-the-skies-today/',
'https://www.piponet.in/youtube-removed-1-9-million-videos-in-india-for-violating-community-guidelines/',
'https://www.piponet.in/amazon-india-working-on-new-ai-to-empower-small-businesses/',
'https://www.piponet.in/bse-share-buyback-price-soars/',
'https://www.piponet.in/celebrating-world-coconut-day/',
'https://www.piponet.in/two-indians-to-participate-in-the-diamond-league-finals/',
'https://www.piponet.in/jk-authorities-take-action-against-pok-based-terrorists-and-their-assets/',
'https://www.piponet.in/sobhita-dhulipala-redefines-style-with-the-three-piece-suit/',
'https://www.piponet.in/karnataka-cm-siddaramaiah-urges-pm-to-reconsider-sanatan-dharma-remark/',
'https://www.piponet.in/raghav-chadha-expresses-gratitude-for-magical-first-meeting-with-parineeti-chopra/',
'https://www.piponet.in/king-khans-jawan-creates-a-frenzy-as-theatres-erupt-in-celebration/',
'https://www.piponet.in/ms-dhoni-joins-former-us-president-donald-trump-for-a-round-of-golf-at-trump-national-golf-club-bedminster/',
'https://www.piponet.in/priyanka-chopra-stuns-in-sheer-metallic-dress-at-victorias-secret-world-tour-event/',
'https://www.piponet.in/bjp-leads-uttarakhand-bageshwar-bypoll-congress-faces-hurdles-from-sp-ally/',
'https://www.piponet.in/shah-rukh-khans-jawan-smashes-opening-day-records-targets-rs-150-crore-worldwide-debut/',
'https://www.piponet.in/pakistani-actor-nausheen-shah-criticizes-kangana-ranaut-sparks-debate/',
'https://www.piponet.in/nasa-working-to-surpass-james-webb-telescope/',
'https://www.piponet.in/barbie-officially-2023s-highest-grossing-film/',
'https://www.piponet.in/new-study-shows-another-harmful-side-effect-of-vaping/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/mother-won-fifa-best-fan-award-in-2019-for-explaining-the-game-to-her-blind-son/',
'https://www.piponet.in/man-arrested-for-aggravated-homosexuality-in-uganda/',
'https://www.piponet.in/news-g20-summit-highlights-indias-role-in-global-diplomacy-and-cooperation/',
'https://www.piponet.in/dia-mirza-radiates-sustainability-in-recycled-outfit-at-planet-indias-launch/',
'https://www.piponet.in/from-home-cooked-food-to-a-dedicated-room-chandrababu-naidus-jail-perks/',
'https://www.piponet.in/chaos-ensues-at-ar-rahman-concert-in-chennai-as-fans-decry-mismanagement/',
'https://www.piponet.in/from-silver-screen-to-safe-streets-jawan-inspired-road-safety-drive-by-delhi-and-up-police/',
'https://www.piponet.in/mercedes-makes-playful-gesture-on-bmws-100th-anniversary/',
'https://www.piponet.in/scientists-grow-human-embryo-without-sperm-or-egg/',
'https://www.piponet.in/cristiano-ronaldo-has-not-been-nominated-for-the-ballon-dor-for-the-first-time-in-19-years/',
'https://www.piponet.in/2-people-smash-hole-in-great-wall-of-china-to-reach-work-more-conveniently/',
'https://www.piponet.in/mexico-supreme-court-rules-that-abortion-is-not-a-crime-anymore/',
'https://www.piponet.in/virat-kohlis-gritty-century-shines-amidst-exhausting-ind-vs-pak-clash/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/chennai-welcomes-the-rebirth-of-indian-terrains-flagship-fashion-destination/',
'https://www.piponet.in/human-rights-take-a-back-seat-as-biden-bolsters-ties-in-vietnam-and-india/',
'https://www.piponet.in/ar-rahman-responds-to-concert-chaos-offers-refunds-to-disappointed-fans/',
'https://www.piponet.in/jawan-makes-history-srks-action-thriller-crosses-%e2%82%b9300-crores-in-india/',
'https://www.piponet.in/nasa-finds-a-way-to-generate-oxygen-on-mars/',
'https://www.piponet.in/portuguese-man-finds-dinosaur-remains-in-his-backyard/',
'https://www.piponet.in/chandrayaan-3-detects-movement-under-moons-surface/',
'https://www.piponet.in/morocco-devastated-by-6-8-magnitude-earthquake-killing-at-least-600-people/',
'https://www.piponet.in/planet-twice-the-size-of-earth-has-been-discovered/',
'https://www.piponet.in/india-inches-closer-to-asia-cup-final-with-commanding-win-over-sri-lanka/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/championing-sustainable-fashion-akshata-murtys-g20-attire-inspires/',
'https://www.piponet.in/kapil-sibal-questions-governments-dual-face-on-akbar-in-g20-booklet/',
'https://www.piponet.in/ashok-selvan-and-keerthi-pandians-wedding-bells-ring-loud-in-a-joyful-union/',
'https://www.piponet.in/abhishek-bachchan-opens-up-about-aishwarya-rais-role-in-household-responsibilities/',
'https://www.piponet.in/sidemen-charity-match-raises-over-3-million-for-charity/',
'https://www.piponet.in/cristiano-ronaldo-makes-his-morocco-hotel-available-to-the-earthquake-victims/',
'https://www.piponet.in/morocco-earthquake-death-toll-rises-past-2000-one-of-the-worst-disasters-this-year/',
'https://www.piponet.in/scientists-create-fuel-that-could-sustain-life-on-the-moon/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/erling-haaland-shares-unconventional-health-tips-to-help-with-fitness/',
'https://www.piponet.in/renaming-history-blue-darts-bharat-dart-signals-a-transformative-journey/',
'https://www.piponet.in/red-carpet-radiance-rekha-and-bollywood-stars-dazzle-at-global-spa-awards-2023/',
'https://www.piponet.in/aap-promotes-education-over-religion-and-caste-inspired-by-jawan-movie/',
'https://www.piponet.in/jawans-unstoppable-box-office-journey-crosses-rs-700-crore-mark-globally/',
'https://www.piponet.in/bhumi-pednekar-and-shehnaaz-gill-twin-in-pink-as-they-head-to-tiff-with-thank-you-for-coming/',
'https://www.piponet.in/novak-djokovic-wins-us-open-2023/',
'https://www.piponet.in/jawan-movie-review/',
'https://www.piponet.in/prime-minister-modi-addresses-global-trust-deficit-at-g20-summit/',
'https://www.piponet.in/uk-prime-minister-rishi-sunak-visits-akshadham-temple-new-delhi/',
'https://www.piponet.in/india-pakistan-match-to-resume-today-continuing-where-it-left-off/',
'https://www.piponet.in/south-korean-lunar-orbiter-captures-stunning-images-of-chandrayaan-3s-landing-site/',
'https://www.piponet.in/varun-bahls-a-love-letter-to-kashmir-showcases-indian-elegance-in-srinagar/',
'https://www.piponet.in/political-tensions-escalate-opposition-leaders-announce-media-anchor-boycott/',
'https://www.piponet.in/chak-de-india-star-rio-kapadia-passes-away-at-66-bollywood-in-mourning/',
'https://www.piponet.in/nayanthara-surpasses-shah-rukh-khan-as-imdbs-most-popular-indian-star/',
'https://www.piponet.in/luis-rubiales-resigns-as-spanish-football-federation-president/',
'https://www.piponet.in/the-india-to-bharat-name-change-controversy/',
'https://www.piponet.in/jawan-makes-287cr-in-4-days-at-the-box-office/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/most-people-still-refer-to-x-as-twitter/',
'https://www.piponet.in/henry-cavill-expresses-interest-in-live-action-red-dead-redemption-movie-adaptation/',
'https://www.piponet.in/creator-of-the-fidget-spinners-didnt-earn-anything-from-her-creation/',
'https://www.piponet.in/death-toll-in-libya-floods-rises-to-over-11000/',
'https://www.piponet.in/prime-minister-modi-says-goodbye-to-old-parliament-house/',
'https://www.piponet.in/india-obliterate-sri-lankas-batting-win-asia-cup-final/',
'https://www.piponet.in/happy-ganesh-chaturthi-2023/',
'https://www.piponet.in/rohit-sharma-issues-bold-statement-after-asia-cup-win-aiming-for-world-cup/',
'https://www.piponet.in/canada-postpones-trade-mission-to-india-as-relationship-sours/',
'https://www.piponet.in/nipah-virus-outbreak-in-kerala/',
'https://www.piponet.in/a-planet-120-light-years-away-shows-signs-of-life/',
'https://www.piponet.in/christopher-nolan-becomes-third-highest-grossing-director-in-the-world/',
'https://www.piponet.in/jet-airways-founder-naresh-goyal-sent-to-arthur-road-jail-over-loan-fraud-case/',
'https://www.piponet.in/one-soldier-missing-in-counter-terror-operation-in-anantnag-kashmir/',
'https://www.piponet.in/india-bloc-boycotts-shows-of-14-news-anchors/',
'https://www.piponet.in/vladimir-putin-accepts-invitation-from-kim-jong-un-to-go-to-north-korea/',
'https://www.piponet.in/india-secure-their-biggest-victory-of-all-time-against-pakistan/',
'https://www.piponet.in/morocco-national-football-team-donates-blood-to-earthquake-victims/',
'https://www.piponet.in/the-streets-of-levira-portugal-were-flooded-with-wine-due-to-distillery-tank-burst/',
'https://www.piponet.in/scientists-predict-date-when-an-asteroid-could-potentially-hit-the-earth-with-huge-force/',
'https://www.piponet.in/a-unique-and-eco-friendly-way-of-paying-fees-in-assam/',
'https://www.piponet.in/rahul-gandhi-vows-caste-census-for-empowerment-ahead-of-general-elections/',
'https://www.piponet.in/diplomatic-clash-india-and-canada-at-odds-over-nijjar-assassination/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/isros-resilience-attempts-continue-to-reconnect-with-chandrayaan-3s-vikram-lander-and-pragyan-rover/',
'https://www.piponet.in/legal-action-initiated-against-ar-rahmans-concert-organizers-for-ticket-overselling/',
'https://www.piponet.in/ranveer-singh-steps-into-the-dons-shoes-a-new-era-for-the-franchise/',
'https://www.piponet.in/oppenheimer-overtakes-bohemian-rhapsody-as-highest-grossing-biopic-of-all-time/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.piponet.in/canadian-prime-minister-speculates-india-could-be-behind-killing-of-a-prominent-sikh-leader/',
'https://www.piponet.in/medical-milestone-uk-girl-receives-unique-transplant-breaks-immunosuppressant-dependency/',
'https://www.piponet.in/kamal-haasan-to-contest-2024-lok-sabha-polls-in-coimbatore-despite-assembly-setback/',
'https://www.piponet.in/chandrayaan-3-revival-isros-tenacious-efforts-to-reconnect-with-vikram-lander-and-pragyan-rover/',
'https://www.piponet.in/vicky-kaushals-the-great-indian-family-opens-to-lukewarm-response-at-box-office/',
'https://www.piponet.in/alia-bhatts-milan-fashion-week-glam-neon-green-stunner/',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',
'https://www.youtube.com/embed/EfLBPHD8vU8?si=jhpRtq-U9vr9Khuu&autoplay=1',

]