import React, { useState,useRef,lazy, useContext, useEffect} from "react";
import "../../src/main.css";
import Imgs from "../common/imgImports";
import { InputGroup, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "video-react/dist/video-react.css";
import { useTranslation } from "react-i18next";
import { localhostURL } from "../common/baseUrl";
import platform from "platform";
import {deviceDetect,mobileModel} from "react-device-detect";
import moment from "moment/moment";
import Logo from "./logoComponent"
import {InsAdSopt} from './ad3i';  
import { AdContext,AdIdContext } from "../components/adManagerContext";
import { userVisit, setSessionFn } from '../common/commanfn';

const FooterComponent = lazy(() => import('./footer'));

const PhoneNumber = () => {
  const [number, setNumber] = useState("");
  const {addManager, setAddManager} = useContext(AdContext);
  const [ad1, setAd1] = useState(addManager?.find(itm => itm.ad_container=="CP_Enter_MobileTop"));
  const [ad2, setAd2] = useState(addManager?.find(itm => itm.ad_container=="CP_Enter_MobileCenter"));
  const [ad3, setAd3] = useState(addManager?.find(itm => itm.ad_container=="CP_Enter_MobileBottom"));
  // eslint-disable-next-line
  const {locationPath, bunConfig} = useContext(AdIdContext); 
  const configTimeout = bunConfig[0]?.CP_Enter_Mobile;
  const [seconds, setSeconds] = useState(configTimeout)
	useEffect(() => {
		const interval = setInterval(() => {
			setSeconds(seconds=> seconds-1)
		}, 1000);
		if(seconds < 0){
			clearInterval(interval)
		}
	}, [configTimeout]);

  useEffect(() =>{
    setAd1(addManager?.find(itm => itm?.ad_container === "CP_Enter_MobileTop"));
    setAd2(addManager?.find(itm => itm?.ad_container === "CP_Enter_MobileCenter"));
    setAd3(addManager?.find(itm => itm?.ad_container === "CP_Enter_MobileBottom"));
  },[addManager])

  const navigate = useNavigate();
  const { t } = useTranslation();
  let getDate = new Date();
  const inputRef = useRef(null);
  let formattedGetDate = moment(getDate).format("YY-MM-DD");
  let formattedGetTime = moment(getDate).format("YY-MM-DD HH:MM:SS");
  let platformOs = platform.os.family;
  let platformOsVersion = platform.os.version;
  localStorage.setItem("phoneNumber", JSON.stringify(number));
  const macAdress=window.localStorage.getItem("userMac");
  const useIp= window.localStorage.getItem("userIp");
  const nasId= window.localStorage.getItem("nasId");
  const sesId= window.localStorage.getItem("sessionId");
  const nasportId= window.localStorage.getItem("nasPortId");
  const st_code= window.localStorage.getItem("stCode");
  
const reqBody = {
  name: "",
  mobileno: number,
  firstname:"",
  middlename:"",
  lastname:"",
  address_1:"",
  address_2:"",
  address_3:"",
  pincode:"",
  city:"",
  state:"",
  age:"",
  gender:"",
  mobile_operator:"",
  macAddr: macAdress,
  operating_system:platformOs,
  network: "",
  os_version:platformOsVersion,
  browser: platform.name,
  browser_ver: platform.version,
  imei:"",
  mobile_make: deviceDetect().vendor,
  mobile_model: mobileModel,
  rec_date:formattedGetDate,
  start_time:formattedGetTime,
  ssid:"",
  idProofType: "",
  idProofNum: "",
  userIp: useIp,
  nasIp: nasId,
  password: "",
  customerId: "",
  nasPortId: nasportId,
  sessionid: sesId,
  isNewMac: false,
  wifi_mode:"",
  email:"",
  gstin:"",
  payment_gateway:"",
  package:"",
  amount:"",
  accountType: 3,
  createledger: true,
  validateOTP: null,
  otp_required:true,
  st_code
};
window.localStorage.setItem("reqBody",JSON.stringify(reqBody));
 
  const sendSms = async () => {
    console.log("Request param---",reqBody);
      await fetch(`${localhostURL}/api/v1/radius/isUserExist`, {
      method: "POST",
      body: JSON.stringify(reqBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((responsedata) => responsedata.json())
    .then((resdata) => {
      localStorage.setItem("cusData",JSON.stringify(resdata.cusData));
      if (resdata.cusData.otp_required == true) {
        navigate("/enterOTP");
      } else if(resdata.cusData.otp_required == false){
        navigate("/enterOTP");
        setTimeout(() => {
          //navigate("/selectWifi");
          navigate("/activeFreewifiPlan");
           }, "5000");
       
      }
    })
    .catch((err) => {
      console.log(err.messsage);
    });
  };
  useEffect(() => { 
    setSessionFn();
    userVisit({mobile_page: 1});
   },[]);
  return (
    <div className="header-main">
      <Logo adSoptData={ad1} locationPath={locationPath}/>
      <div className="video-ad">
      <InsAdSopt adSoptData={ad2} locationPath={locationPath}/> 
      </div>
      <div className="p-num">
        <div className="label-form">{t("description.number")}</div>
        <InputGroup className="in-mob">
          <InputGroup.Text id="basic-addon1">
            <img src={Imgs.flag} className="phone-icon" alt=""></img>
            <span>
              +91 <img src={Imgs.drop} alt="" srcset="" className="ar-down" />
            </span>
          </InputGroup.Text>
          <Form.Control
            placeholder={t("description.phonePlace")}
            aria-label="Username"
            aria-describedby="basic-addon1"
            className="phoneNumber"
            ref={inputRef}
            value={number}
            type="number"
            onChange={(event) => {
              setNumber(event.target.value);
            }}
          />
        </InputGroup>
        <div className="btn-header">
        {number.length === 10 ? (
          <Button
            className="enter-btn"
            onClick={() => {
              sendSms();
             // navigate("/enterOTP");
            }}
            disabled={seconds > 0}
          >
            {t("description.sendCode")}
            {seconds > 0  ?  <>
              <div className="spinner-grow"  role="status">...</div>
            <>{seconds}</>
          </> : null}
          </Button>
        ) : (
          <Button className="enter-btn disabled" disabled>
            {t("description.sendCode")}
          </Button>
        )}
      </div>
      </div>
      {/* <div className="link-user">
        <span
          className="links-a-user1"
          onClick={() => {
            navigate("/user");
          }}
        >
          {t("description.userLogin")}
        </span>
      </div> */}
      <div></div>
      <div className="bottom-ads2 mx-auto">
      {/* <div id='div-gpt-ad-1705475867390-0' style={{'min-width': '300px','min-height': '250px'}}>
  
</div> */}
      <InsAdSopt adSoptData={ad3} locationPath={locationPath}/>
      </div>
      <div className="foot-div">
        <FooterComponent/>
      </div>
    </div>
  );
};

export default PhoneNumber;
