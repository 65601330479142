import "./App.css";
import "./main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState, Suspense,  } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import HeaderComponent from "./components/headerComponent";
import UserLogin from "./components/userLogin";
import WifiComponent from "./components/wifiComponent";
import WifiConnected from "./components/wifiConnect";
import PaidWifiConnected from "./components/paidWifiConnect";
import PhoneNumber from "./components/phoneNumber";
import OtpComponent from "./components/otpComponent";
import PaidOptions from "./components/choosePaidWifi";
import UserFeedback from "./components/feedback";
import AddEnquiry from "./components/adenquiry";
import Payment from "./components/payment";
import Success from "./components/paymentStatus";
import PaidWifiExpired from "./components/paidExpire";
import ActiveFreeWIFI from "./components/activeFreewifi";
import TrackApi from "./components/TrackRecords";
import { AdContext, AdIdContext,LocationData } from "./components/adManagerContext";
import { AAAURL,localhostURL } from "./common/baseUrl";
import platform from "platform";
import {deviceDetect,mobileModel} from "react-device-detect";
import DeviceInfo from 'react-native-device-info';
import { v4 as uuid } from 'uuid';
import { useNavigate } from "react-router-dom";
import {userVisit, setSessionFn} from './common/commanfn';
function App() {
  const bannerAdDefault ="62cf573e";
  const [addManager, setAddManager] = useState([]);
  const [bunConfig, setBunConfig] = useState([]);

  const location = useLocation();
  const [adSoptIdhdr, setAdSoptIdhdr] = useState(bannerAdDefault);
  const [locationPath,setLocationPath] = useState(null)
  let platformOs = platform.os.family;
  let platformOsVersion = platform.os.version;
  const mcadr = getMacAddress();
	const ipadr = getUserIPAddress();
	const nid = getNasId();
	const nasportid = getNasPortId();
	const sessionid = getSessionId();
  const aaaURL = getAAAURL();
  const stCode= getStCode();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  var id;
  const macId =  DeviceInfo.getMacAddress(); 
 
 //alert(macId)
  let browserName= platform.name;
  let browserVersion = platform.version;
  let platforget = platform.manufacturer;
  console.log("Browser Manufacturer",platforget);
  let operating_system = platformOs + platformOsVersion;
  console.log("opertaing system",operating_system);
  //var uniqueId = DeviceInfo.getUniqueId();
  //console.log("uid-",uniqueId);
  const unique_id = uuid();
  window.localStorage.setItem("userMac",mcadr);
  window.localStorage.setItem("userIp",ipadr);
  window.localStorage.setItem("nasId",nid);
  window.localStorage.setItem("sessionId",sessionid);
  window.localStorage.setItem("nasPortId",nasportid);
  window.localStorage.setItem("aaaUrl",aaaURL);
  window.localStorage.setItem("stCode",stCode);
  var urlAAA = AAAURL +"/userportal/newlogin.do?strMode=APICall&username="+"Railtel"+"&password="
  +"12345"+"&nasIp="+nid+"&ip="+ipadr+"&mac="+mcadr+"&sessionId="+"109874748"+"&pkgno="+"1"
  +"&actno="+"444"+"&subsno="+"44"+"&serviceclose=REDIRECT&epgpolicy=inventum";
  console.log("AAAURL----",urlAAA);
  const reqBody = {
    operating_system:platformOs,
    os_version:platformOsVersion,
    browser: platform.name,
    browser_ver: platform.version,
    mobile_make: deviceDetect().vendor,
    mobile_model: mobileModel,
    userIp: ipadr,
    nasIp: nid,
    nasPortId: nasportid,
    sessionid: sessionid,
    macAddr: mcadr,
    st_code:stCode
  }
  
  
  function getNasPortId(){
    var nasportid = window.localStorage.getItem("nasPortId");
    if(nasportid) {
      const url = new URL(window.location.href).searchParams;
      const nasportidFromUrl = url.get('nasPortId');
      if(nasportidFromUrl) {
        return nasportidFromUrl;
      } else {
        return nasportid;
      }
    } else {
      const url = new URL(window.location.href).searchParams;
      nasportid = url.get('nasPortId');
      return nasportid;
    }
  }

  function getSessionId(){
    var sessionid = window.localStorage.getItem("sessionId");
    if(sessionid) {
      const url = new URL(window.location.href).searchParams;
      var sessionIdFromURL = url.get('session-id');
      if(sessionIdFromURL) {
        return sessionIdFromURL;
      } else {
        return sessionid;
      }
    } else {
      const url = new URL(window.location.href).searchParams;
      sessionid = url.get('session-id');
      return sessionid;
    }
  
  }
  
  function getMacAddress(){
    var mac =  window.localStorage.getItem("userMac");
    if(mac) {
      const url = new URL(window.location.href).searchParams;
      var macFromURL = url.get('usermac');
      if(macFromURL) {
        return macFromURL;
      } else {
        return mac;
      }
    } else {
      const url = new URL(window.location.href).searchParams;
      mac = url.get('usermac');
      if(mac){
        return mac;
      } else {
        return null;
      }
    }
  }
  function getUserIPAddress(){
    var useraddr = window.localStorage.getItem("userIp");
    if(useraddr) {
      const url = new URL(window.location.href).searchParams;
      var useraddrFromURL = url.get('useraddr');
      if(useraddrFromURL) {
        return useraddrFromURL;
      } else {
        return useraddr;
      }
    } else {
      const url = new URL(window.location.href).searchParams;
      useraddr = url.get('useraddr');
      if(useraddr){
        return useraddr;
      } else {
        return null;
      }
    }
  }
  function getNasId(){
    var nasIp = window.localStorage.getItem("nasId");
    if(nasIp) {
      const url = new URL(window.location.href).searchParams;
      var nasIPFromURL = url.get('nasip');
      if(nasIPFromURL) {
        return nasIPFromURL;
      } else {
        return nasIp;
      }
    } else {
      const url = new URL(window.location.href).searchParams;
      nasIp = url.get('nasip');
      if(nasIp){
        return nasIp;
      } else {
        return null;
      }
    }
  }
  function getStCode(){
    var stCode = window.localStorage.getItem("stCode");
    if(stCode) {
      const url = new URL(window.location.href).searchParams;
      var stCodeFromURL = url.get('st');
      if(stCodeFromURL) {
        return stCodeFromURL;
      } else {
        return stCode;
      }
    } else {
      const url = new URL(window.location.href).searchParams;
      stCode = url.get('st');
      if(stCode){
        return stCode;
      } else {
        return null;
      }
    }
  }
  function getAAAURL(){
    const urlApi=window.location.href;
    var pathArray = urlApi.split( '?' );
    var aaaUrl = pathArray[0].substring(0,pathArray[0].length-1);
    return aaaUrl;
  }
  
 const pageWideHeaderAdd = (pageLocation) => {
    let page = bannerAdDefault;
    if(pageLocation ==="/" || pageLocation ==='/?') {
      return page;
    }else if(pageLocation.indexOf("phoneNumber") > 0){
      return '15c867a8';
    }  else if(pageLocation.indexOf("enterOTP") > 0){
      return '8cc13612';
    } else if(pageLocation.indexOf("selectWifi") > 0){
      return 'fbc60684';
    }  else if(pageLocation.indexOf("wifiConnected") > 0){
      return '6b791b15';
    } else if(pageLocation.indexOf("selectPaidOptions") > 0){
      return '1c7e2b83';
    } 
    else if(pageLocation.indexOf("activeFreewifiPlan") > 0){
      return 'df333f3c';
    } 
  } 
  const isUserVisit =  () => {
    
    console.log("Request param---",reqBody);
    //await fetch(`${BaseURL}/sendsms/post `, {
       fetch(`${localhostURL}/api/v1/radius/isUserLanded`, {
      method: "POST",
      body: JSON.stringify(reqBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((responsedata) => responsedata.json())
    .then((resdata) => {
        // setStationCode(resdata.locData[0].station_code)
        console.log("User Data------>>>>",resdata.locData[0].district);
        console.log("Response CUST Data-------------------",resdata);
        setLocationPath(resdata.locData[0]);
        userVisit({landing_page: 1, station_code: resdata.locData[0].station_code})
        window.VMAXSDK_3i.User.setCity(resdata.locData[0].district);
        window.VMAXSDK_3i.User.setZipCode(resdata.locData[0].pincode);
        window.VMAXSDK_3i.User.setRegion(resdata.locData[0].state);
        window.VMAXSDK_3i.User.setIdentifier(mcadr);
        window.VMAXSDK_3i.App.setCustomData({
          "city_tier": resdata.locData[0].city_tier, 
          "district": resdata.locData[0].district,
          "pincode":resdata.locData[0].pincode,
          "region": resdata.locData[0].region,
          "state": resdata.locData[0].state,
          "station_name": resdata.locData[0].station_name,
          "operating_system":platformOs,
          "os_version":platformOsVersion,
          "browser": platform.name,
          "browser_ver": platform.version,
          "mobile_make": deviceDetect().vendor,
          "mobile_model": mobileModel,
          "userIp": ipadr
          });

          
      })
      .catch((err) => {
        console.log(err.messsage);
      });
  };
  useEffect(() => { 
    /**code commented for running this on local without commenting this code localy generating error
     * 
     * */
    isUserVisit();
    setSessionFn();
    
    console.log("isUserVisit called")
   },[]);



   useEffect(() => {
    try{
      setBunConfig(JSON.parse(process.env.REACT_APP_BTN_TIME_OUT))
    }catch(e){
      console.log("Error ", e)
    }
   

  }, []);

  

  // function handleParamUpdate() {
  //   queryParams.set("nasip", nasportid);
  //   const newSearch = `?${queryParams.toString()}`;
  //   navigate({ search: newSearch });
  // }
  // useEffect(() => {
  //   handleParamUpdate();
  // },[]);
  useEffect(() => {
    const adSpID =pageWideHeaderAdd(location.pathname);
    setAdSoptIdhdr(adSpID)
  },[location ])

  useEffect(() => { 
    // code for get ads from admin
    if(locationPath){
    //  ${locationPath.station_code}
     fetch(`${localhostURL}/adminapi/v1/ads-station?stState=${locationPath.state}&stCity=${locationPath.district}&stCode=${locationPath.station_code}`)
     .then(res => res.json())
     .then(data => {  
       console.log("ads==> data==>", data);
       setAddManager(data)
     })
    }
  //End code
},[locationPath]);
  
  return (
    <div className="App">
      {process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE : process.env.REACT_APP_PRO_MODE}
      {console.log("data under context=>", addManager)}
       <Suspense fallback={<div>Loading.....</div> }>
        <AdContext.Provider value={{ addManager}}>
        <AdIdContext.Provider value={{ adSoptIdhdr, locationPath, bunConfig}}>  
      <Routes>
        <Route path="/" element={<HeaderComponent />} />
        <Route path="/paymentStatus" element={< Success />} />
        <Route path="/activeFreewifiPlan" element={< ActiveFreeWIFI />} /> 
        <Route path="/paidWifiExpired" element={< PaidWifiExpired  />} />
        <Route path="/payment" element={< Payment />} />
        <Route path="/user" element={<UserLogin />} />
        <Route path="/selectWifi" element={<WifiComponent />} />
        <Route path="/wifiConnected" element={<WifiConnected />} />
        <Route path="/phoneNumber" element={<PhoneNumber />} />
        <Route path="/enterOTP" element={<OtpComponent />} />
        <Route path="/selectPaidOptions" element={<PaidOptions />} />
        <Route path="/paidwifiConnected" element={<PaidWifiConnected />} />
        <Route path="/submitFeedback" element={<UserFeedback />} />
        <Route path="/submitAdEnquiry" element={<AddEnquiry />} />
        <Route path="/trackapi" element={<TrackApi />} />
      </Routes>
      </AdIdContext.Provider> 
      </AdContext.Provider> 
     
      </Suspense>
    </div>
  );
}

export default App;
