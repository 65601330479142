import React, { lazy , useContext} from "react";
import "../../src/main.css";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BiRupee } from "react-icons/bi";
import "video-react/dist/video-react.css";
import { FaWifi } from "react-icons/fa";
import {AdContext, AdIdContext} from './adManagerContext'

const LogoComponent = lazy(() => import('./logoComponent'));
const FooterComponent = lazy(() => import('./footer'));

const PaidWifiExpired = () => {
  const { t } = useTranslation();
  // eslint-disable-next-line
  const {addManager, setAddManager} = useContext(AdContext);
  const {locationPath} = useContext(AdIdContext);
  const navigate = useNavigate();
  return (
    <div className="header-main">
      <LogoComponent />
      <div className="video-ad">
      {addManager && addManager.length && addManager?.find(itm => itm.adSopt === "ce9da01f")?.isEnable === true ?(
         <div id ="ad_container20"><ins id="a2" data-meta={JSON.stringify(locationPath)} data-adspot-key="ce9da01f"></ins></div>): null
        } 
      </div>
      <div>
        <FaWifi className="wifi-connect-icon"></FaWifi>
      </div>
      <div>
        <span className="wifi-connect">{t("description.wifiConnect")}</span>
      </div>
      <div className="card-wifi" style={{ marginTop: "15px" }}>
        <div className="card-body1">
          <h3 className="first-span">
            <BiRupee></BiRupee>35.59
          </h3>
          <h6 className="second-span">{t("description.expired")}</h6>
        </div>
        <Button
          variant="primary"
          className="buy-btn"
          onClick={() => navigate("/selectPaidOptions")}
        >
          {t("description.plans")}
        </Button>
      </div>
      <div className="bottom-ads2 mx-auto">
      {addManager && addManager.length && addManager?.find(itm => itm.adSopt === "36509a09")?.isEnable === true ?(
         <div id ="ad_container21"><ins id="a3" data-meta={JSON.stringify(locationPath)} data-adspot-key="36509a09"></ins></div>): null
        } 
        
      </div>

      {/* <div className="store-img">
        <div>
          <span className="free-text-1">{t("description.footerText")}</span>
        </div>
        <img
          src={Imgs.playStore}
          alt=""
          srcset=""
          className="playStore"
          onClick={() => {
            window.location.href =
              "https://play.google.com/store/apps/details?id=com.railmitra.www&hl=en_IN&utm_source=RailMitraWebsite&utm_medium=FooterAllPage&utm_campaign=FooterAppLink";
          }}
        />
        <img src={Imgs.appStore} alt="" srcset="" className="appStore" />
      </div> */}
      {/* footer links  */}

      <FooterComponent />
    </div>
  );
};

export default PaidWifiExpired;
