import React from "react";
import Imgs from "../common/imgImports";
import DyImage from "./dyImage"
import DyPlayer from "./dyPlayer"

import { localhostURL } from "../common/baseUrl";

const Ad3i = ({adSoptData, locationPath}) => {
  const vedioAPI =`${localhostURL}/api/v1/ad/video`
  return (
    
    <div key={adSoptData?.SrNo} className={'xhidden localAd 3i-ads-contaner'+ + adSoptData?.SrNo} >
      {/* { 
      console.log("{console.log(Imgs?.ads3i['imgAd_'+ adSoptData?.adSopt])}", Imgs?.ads3i['adVed_'+ adSoptData?.adSopt], adSoptData)
      } */}
      {adSoptData && adSoptData?.adType === "Video" ?(
         <DyPlayer 
         key={adSoptData?.SrNo}
         data-meta={JSON.stringify(locationPath)}
         placeholder={Imgs?.ads3i['adVed_'+ adSoptData?.adSopt]}
         src={localhostURL +'/ad-assets/ads/videos/'+ adSoptData.AdsFileData}
       >
         {/* <source src={vedioAPI} type="video/mp4" /> */}
         
       </DyPlayer>): (<a href={adSoptData.link}><DyImage key={adSoptData?.SrNo} alt={adSoptData?.SrNo} src={localhostURL +'/ad-assets/ads/images/'+ adSoptData.AdsFileData} placeholder={Imgs?.ads3i['imgAd_'+ adSoptData?.adSopt]} /></a>)
      }
      {/*</ImageDy> src={Imgs?.ads3i['imgAd_'+ adSoptData?.adSopt]} data-meta={JSON.stringify(locationPath)} alt="" srcset="" className="first-img2 img-fluid" />
       )*/}
    </div>
  );
};

const InsAdSopt = ({adSoptData, locationPath, adSopts2= {}}) => {
  const strData = `{'city_tier': '${locationPath?.city_tier}', 'pincode': '${locationPath?.pincode}', 'region': '${locationPath?.region}', 'state': '${locationPath?.state}', 'station_name': '${locationPath?.station_name}' }`
  return (
    <> 
    {adSoptData && adSoptData?.adIsEnable === true ? (
      <>
        {adSoptData && adSoptData?.isVmaxAds === true ?(
         <div key={adSoptData?.SrNo} id ={'ad_container'+ adSoptData?.SrNo} className="xhidden">
          <ins id={'a'+ adSoptData?.SrNo} data-meta={strData} data-adspot-key={adSoptData?.adSopt}></ins>
          {(adSopts2 && adSopts2?.SrNo && adSopts2?.isVmaxAds === true)? (
            <ins id={'a'+ adSopts2?.SrNo} data-meta={strData} data-adspot-key={adSopts2?.adSopt}></ins>
          ):null}
          </div>
          ): null
        }
        {adSoptData && adSoptData?.isVmaxAds === false &&  adSoptData.is3IAd === true?(
         <Ad3i adSoptData={adSoptData} locationPath={locationPath}/>
          ): null
        }
        {(adSopts2 && adSopts2?.SrNo && adSopts2?.isVmaxAds === false && adSopts2.is3IAd === true)? (
            <Ad3i adSoptData={adSoptData} locationPath={locationPath}/>
          ):null}
        </>): null}
    </>
  );
};

export {
  Ad3i,
  InsAdSopt
} ;
