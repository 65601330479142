import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          description: {
            lang:"English",
            part1: "Enter Username",
            part2: "Enter Password",
            part3: "Choose Your Language",
            part4: "Select",
            welcomeText:"Welcome to RailTel High-Speed Wi-Fi",
            aboutUs:"About Us:",
            connect:"Connect To Wi-Fi",
            contact:"Contact No:",
            contactNo:"+124-2714000",
            feedback:"For Feedback",
            click:"Click here",
            ad:"For Advertising with us,",
            sendCode:"Send Code",
            usernamePlace: "Username",
            passworPlace: "Password",
            usernamePlace1: "Full Name",
            submit:"SUBMIT",
            login: "Login",
            //complaint: "For any complaints/issues ",
            // feedback:"Feedback",
            AdEnquiry:"Ad Enquiry",
            amountPaid:"Amonut Paid",
            tId:"Transaction Id",
            bankId:"Bank",
            payMethod:"Payment Method",
            reason:"Reason",
            dnt:"Date and Time",
            paytitlefail:"Payment Failure!",
            paytitlesuc:"Payment Successful!",
            continue:"Continiue",
            print:"Print",
            close:"Close",
            complaint:"Comments",
            complaint1:"Summary of requirement ",
            email: "Email",
            userLogin:" RailTel Users Login",
            connectWifi: "Connect to Wi-Fi",
            connectMobileApp:"Connect using Mobile App",
            connectBrowser:"Connect using Browser",
            FreewifiBrowser:"Free WiFi for 60 mins @ 10 Mbps",
            FreewifiApp:"Free WiFi for 4 hours @ 5 Mbps",
            user: "RailWire User",
            userClick: "Click Here",
            termsText: "By connecting, you agree to the ",
            termsText2: "",
            termsClick: "Terms & Conditions",
            irctcText: "To access www.irctc.in",
            indiaRail: "To access www.indiarail.gov.in",
            number: "Enter a Mobile Number",
            phonePlace: "Enter mobile number",
            state:"State",
            otp: "Enter OTP Number",
            otpPlace:"Enter OTP",
            changeNum: "Change Mobile Number",
            cmp1:"Didn't get OTP?",
            sec: "OTP Expires in",
            otpExpire: "OTP Expired",
            otpResend: "Resend OTP",
            verify:"Verify",
            validate:"Validating User.....",
            noFreeWifi:"No Free wifi plan available.Please choose paid wifi option",
            freeWifi: " Free Wi-Fi",
            freeSpeed: "60 Min, Normal Speed",
            paidWifi: "Paid Wi-Fi",
            paidSpeed: "High Speed",
            validity:"Plan valid till",
            ValidateUser:"Please enter your name",
            remaining:"left",
            expired:"Plan Expired",
            plans:"View Plans",
            pincode:"pincode",
            GSTIN:"GSTIN",
            Address:"Address",
            validateEmail:"Please enter a valid email",
            PaymentGateway:"Payment Gateway",
            SelecteOption:"Selecte Option",
            sCode:"Station Code",
            stateSelection:"Please select state",
            PhonePe:"PhonePe",
            messageLine:"To connect directly : Pls send us an email to",
            Mailto:"Send Mail",
            feedbackSelect1:"Paid plan subscribed but plan is not activated",
            feedbackSelect2:"Paid plan-Internet speed is slow",
            feedbackSelect3:"Issue within Captive Portal movement",
            feedbackSelect4:"Not able to receive OTP",
            feedbackSelect5:"Free Internet not activated",
            feedbackSelect6:"Other",
            GooglePay:"GooglePay",
            skipad:"Skip Ad to Connect WiFi",
            paymentAmount:"Payment Amount",
            Paytm:"Paytm",
            NetBanking:"Net Banking", 
            cancel:"Cancel",
            pay:"Pay",
            category:"Category",
            paymentNumber:"Payment Reference No",
            validNumber:"Please enter a valid mobile number",
            validatePayment:"Please select one of the payment option",
            validStatefield:"Please select your state",
            validScodefield:"Please select your station name",
            validateComplain:"Please select your complain category",
            wifiConnect: "Congratulations!!! You are connected to RailWire WIFI",
            wifiConnect1: "You can use free Internet for 60 minutes.",
            wifiConnect2: "Have a great day!!!",
            footerText:
              "For extended WiFi access please download RailMitraa app.",
            paidConnect: "Your selected plan 34Mbps 20GB/Days is active.",
            paidOptionText: "Select your Wi-Fi Service Plan",
            option: "34Mbps 20GB/5 Days",
            buy: "Buy",
            aboutUsText:`RailWire services are offered by RailTel Corporation of India Ltd. (‘RailTel), a Public Sector Undertaking (PSU) under Ministry of Railways, having its 
            Registered office at Plate-A, 6th Floor, Office Block Tower-2, East Kidwai Nagar, 
            New Delhi- 110023, CIN : U64202DL2000GO|107905. Railwire is an initiative of RailTel. 
            All services offered under this initiative shall be referred to as 
            being offered by “RailWire Operations” or simply by “RailWire”`,
            modalFooterText:`The terms and conditions stated below form an integral 
            part of subscriber acceptance for availing of the Railwire Wi-Fi service at this Venue. 1. 
            “Customer/subscriber” means any person who avails the Railwire services(s) at Venues`,
            
            
          },
        },
      },
      hi: {
        translation: {
          description: {
            lang:"हिंदी",
            welcomeText:"रेलटेल हाई-स्पीड वाई-फाई में आपका स्वागत है",
            part1: "उपयोगकर्ता नाम दर्ज करें",
            state:"राज्य",
            sCode:"स्टेशन कोड",
            part2: "पास वर्ड दर्ज करें",
            part3: "अपनी भाषा चुनें",
            stateSelection:"कृपया राज्य का चयन करें",
            part4: "चुनना",
            validScodefield:"कृपया अपने स्टेशन का नाम चुनें",
            paymentNumber:"भुगतान संदर्भ संख्या",
            SelecteOption:"विकल्प चुनें",
            validatePayment:"कृपया भुगतान विकल्प में से एक का चयन करें",
            paymentAmount:"भुगतान राशि",
            validStatefield:"कृपया अपने राज्य का चयन करें",
            validateComplain:"कृपया अपनी शिकायत श्रेणी का चयन करें",
            connect:"Wi-Fi से कनेक्ट करें",
            skipad:"विज्ञापन को छोड़ें",
            connectMobileApp:"मोबाइल ऐप का उपयोग करके कनेक्ट करें",
            connectBrowser:"ब्राउज़र का उपयोग कर कनेक्ट करें ",
            FreewifiBrowser:"60 मिनट @ 10 एमबीपीएस के लिए मुफ्त वाई-फाई / सशुल्",
            FreewifiApp:"5 एमबीपीएस की दर से 4 घंटे के लिए फ्री वाई-फाई",
            aboutUs:"हमारे बारे में:",
            validate:"उपयोगकर्ता को मान्य किया जा रहा है...",
            cancel:"रद्द करना",
            pay:"भुगतान करना",
            contact:"संपर्क नंबर:",
            contactNo:"+124-2714000",
            PhonePe:"फोनपे",
            GooglePay:"गूगल पे",
            NetBanking:"नेट बैंकिंग",
            feedback:"प्रतिक्रिया के लिए",
            feedbackSelect1:"सशुल्क योजना की सदस्यता ले ली गई है लेकिन योजना सक्रिय नहीं है",
            feedbackSelect2:"सशुल्क योजना - इंटरनेट की गति धीमी है।",
            feedbackSelect3:"कैप्टिव पोर्टल आंदोलन के भीतर मुद्दा है।",
            feedbackSelect4:"ओटीपी प्राप्त नहीं हो पा रहा है",
            feedbackSelect5:"निःशुल्क इंटरनेट सक्रिय नहीं है।",
            feedbackSelect6:"अन्य",
            noFreeWifi:"कोई निःशुल्क वाई-फ़ाई योजना उपलब्ध नहीं है। कृपया सशुल्क वाई-फ़ाई विकल्प चुनें",
            click:"यहाँ क्लिक करें",
            ad:"हमारे साथ विज्ञापन करने के लिए,",
            sendCode: "कोड भेजें",
            pincode:"पिन कोड",
            GSTIN:"जीएसटीआईएन",
            Address:"पता",
            usernamePlace: "उपयोगकर्ता नाम",
            passworPlace: "पासवर्ड",
            usernamePlace1: "पूरा नाम",
            messageLine:"सीधे जुड़ने के लिए: कृपया हमें एक ईमेल भेजें",
            Mailto:"मेल भेजने",
            submit:"जमा करें",
            login: "लॉग इन करें",
            PaymentGateway:"भुगतान गेटवे",
            validNumber:"कृपया एक वैध मोबाइल नंबर दर्ज करें।",
            ValidateUser:"कृपया अपना नाम दर्ज करें",
            validateEmail:"कृपया एक मान्य ईमेल दर्ज करें",
            complaint: "किसी भी शिकायत/समस्या के लिए",
            complaint1:"आवश्यकता का सारांश",
            email: " ईमेल",
            userLogin:"रेलटेल उपयोगकर्ता लॉगिन",
            connectWifi: "वाई-फाई से कनेक्ट करें",
            feedback:"प्रतिक्रिया",
            category:"वर्ग",
            AdEnquiry:"पूछताछ जोड़ें",
            amountPaid:"राशि का भुगतान",
            tId:"लेन-देन आईडी",
            bankId:"बैंक",
            payMethod:"भुगतान विधि",
            reason:"कारण",
            dnt:"तिथि और समय",
            paytitlefail:"भुगतान विफलता!",
            paytitlesuc:"भुगतान सफल!",
            continue:"जारी रखना",
            print:"छपाई",
            close:"बंद करना",
            user: "रेलवायर उपयोगकर्ता",
            userClick: "यहाँ क्लिक करें",
            termsText: "कनेक्ट करके आप इससे सहमत हैं ",
            termsClick: "नियम और शर्तें",
            irctcText: "www.irctc.in पर जाने के लिए",
            indiaRail: "www.indiarail.gov.in पर जाने के लिए",
            number: "एक मान्य मोबाइल नंबर दर्ज करें",
            phonePlace: "फ़ोन नंबर",
            otp: "ओटीपी दर्ज करें",
            otpPlace:"OTP दर्ज करें",
            changeNum: "फ़ोन नंबर बदलें",
            cmp1:"OTP नहीं मिला?",
            sec: "ओटीपी 60 सेकंड में समाप्त हो जाता है",
            otpExpire: "ओटीपी समाप्त हो गया",
            otpResend: "ओटीपी पुनः भेजें",
            verify:"सत्यापित करें",
            freeWifi: " नि: शुल्क वाई - फाई",
            freeSpeed: "60 मिनट मुफ्त वाईफाई, सामान्य गति",
            paidWifi: "सशुल्क वाई-फाई",
            validity: "तक वैध योजना",
            remaining:"बचा हुआ",
            expired:"योजना समाप्त हो गई",
            plans:"योजनाएं देखें",
            paidSpeed: "भुगतान की गति",
            wifiConnect: "RailTel वाईफाई से जुड़ा",
            wifiConnect1:
            "आप 60 मिनट के लिए मुफ्त इंटरनेट का उपयोग कर सकते हैं।",
            wifiConnect2: "आपका दिन अच्छा रहे!!!",
            footerText:
            "विस्तारित वाईफाई एक्सेस के लिए कृपया RailMitraa ऐप डाउनलोड करें।",
            paidConnect: "आपका चयनित प्लान 34Mbps 20GB/दिन सक्रिय है।",
            paidOptionText: "अपनी वाई-फाई सेवा योजना का चयन करें",
            option: "34Mbps 20GB/5 दिन",
            buy: "खरीदना",
            aboutUsText:"रेलवे मंत्रालय के अधीनस्थ एक लोक सेवा उपक्रम ('रेलटेल') भारत कॉर्पोरेशन लिमिटेड द्वारा रेलवे स्टेशनों पर रेलटेल ब्रॉडबैंड लिमिटेड द्वारा प्रदान की जाने वाली वाईफ़ाई सेवाएं हैं। रेलटेल का पंजीकृत कार्यालय ईस्ट किडवाई नगर, नई दिल्ली-110023 में है। रेलटेल की पहल रेलवे के उद्देश्यों को पूरा करने के लिए शुरू की गई है। इस पहल के तहत प्रदान की जाने वाली सभी सेवाएं “रेलटेल ऑपरेशंस” द्वारा प्रदान की जाती हैं या सिर्फ द्वारा “रेलटेल” द्वारा।",
            modalFooterText:"निम्नलिखित नियम और शर्तें वेन्यू पर रेलटेल वाई-फाई सेवा का लाभ उठाने के लिए ग्राहक स्वीकृति का एक अभिन्न अंग हैं। 1. “ग्राहक / सब्सक्राइबर” किसी व्यक्ति को दर्शनों पर रेलटेल सेवा (ओं) का लाभ उठाने वाला व्यक्ति होता है।",
            
          },
        },
      },
      mrt: {
        translation: {
          description: {
            lang:"मराठी",
            welcomeText:"रेलटेल हाई-स्पीड वाय-फाय वर आपले स्वागत आहे",
            part1: "यूजरनेम टाइप करा",
            part2: "पासवर्ड टाइप करा",
            part3: "आपली भाषा निवडा",
            part4: "निवडा",
            welcomeText: "RailTel हाय स्पीड व्हाई-फायच्या वर आपले स्वागत आहे",
            aboutUs: "आमच्याबद्दल:",
            connect: "व्हाईफायशी कनेक्ट करा",
            contact: "संपर्क क्रमांक:",
            contactNo: "+124-2714000",
            feedback: "फिडबॅकसाठी,",
            click: "येथे क्लिक करा",
            ad: "आमच्यासह जाहिरात देण्यासाठी,",
            sendCode: "कोड पाठवा",
            usernamePlace: "वापरकर्तानाव",
            Cancel:"रद्द करना",
            Pay:"भुगतान करना",
            passworPlace: "पासवर्ड",
            submit:"सबमिट करा",
            email: "ग्राहक देखभाल ईमेल",
            userLogin: "RailTel वापरकर्ते लॉगिन",
            connectWifi: "व्हाई-फायच्या संवाद साधा",
            user: "RailWire वापरकर्ता",
            userClick: "येथे क्लिक करा",
            termsText: "कनेक्ट करताना, आपण सहमत आहात",
            termsText2: " ",
            termsClick: "नियम आणि अटींची",
            irctcText: "www.irctc.in प्रवेश करण्यासाठी",
            indiaRail: "www.indiarail.gov.in प्रवेश करण्यासाठी",
            number: "मोबाईल नंबर प्रविष्ट करा*",
            phonePlace: "मोबाइल नंबर प्रविष्ट करा",
            otp: "ओटीपी नंबर प्रविष्ट करा*",
            otpPlace: "ओटीपी प्रविष्ट करा",
            changeNum: "मोबाईल नंबर बदला",
            cmp1: "OTP मिळालं नाही?",
            sec: "OTP चालू असताना",
            otpExpire: "OTP समाप्त",
            otpResend: "OTP पुन्हा पाठवा",
            verify: "सत्यापित करा",
            freeWifi: "फ्री वाय-फाय",
            freeSpeed: "30 मिनिटे, सामान्य गती",
            paidWifi: "पेड वाय-फाय",
            validity:"पर्यंत वैध योजना",
            remaining:"शेष",
            expired:"योजना समाप्त हो गई",
            plans:"योजनाएं देखें",
            paidSpeed: "उच्च गती",
            wifiConnect: " RailTel वायफायशी संपर्क साधला",
            wifiConnect1: "30 मिनिटे मुक्त इंटरनेट वापरू शकता.",
            wifiConnect2: "छान दिवस आहे !!!",
            paidConnect: "आपली निवडलेली योजना 34Mbps 20GB/दिवस चालू आहे.",
            paidOptionText: "आपल्या वाय-फाय सेवा योजना निवडा",
            option: "34Mbps 20GB/5 दिवस",
            buy: "खरेदी करा",
            footerText:"विस्तारित वायफाय प्रवेशासाठी कृपया RailMitraa अॅप डाउनलोड करा.",
            aboutUsText:"रेलवे टेलीकॉम द्वारा रेलवे करपोरेशन ऑफ इंडिया लिमिटेड ('रेलटेल), रेल मंत्रालय के तहत एक सार्वजनिक क्षेत्र उपक्रम (पीएसयू) है, जिसका पंजीकृत कार्यालय प्लेट-ए, 6 वीं मंजिल, ऑफिस ब्लॉक टॉवर-2, ईस्ट किडवाई नगर, नई दिल्ली- 110023, सीआईएन : यू64202डीएल2000गो | 107905 है। रेलवायर रेलटेल की एक पहल है। इस पहल के तहत ऑफर की जाने वाली सभी सेवाओं को 'रेलवायर ऑपरेशंस' द्वारा ऑफर किया जाता है या सिर्फ 'रेलवायर' के रूप में संदर्भित किया जाएगा।",
            modalFooterText:"खालील नियम आणि अटी या सदस्याच्या स्वीकृतीचा एक महत्त्वाचा भाग आहे ज्यामुळे या स्थळावर रेलवायर वाय-फाय सेवा वापरण्याची सुविधा सदस्यांना दिली जाते. 1. “ग्राहक / सदस्य” हा व्यक्ती आहे जो स्थळांच्या वर रेलवायर सेवा (एस) वापरत असतो.",
          },
        },
      },
      grt:{
        translation:{
          description:{
              lang:"ગુજરાતી",
              part1: "વપરાશકર્તા નામ દાખલ કરો",
              part2: "પાસવર્ડ દાખલ કરો",
              part3: "તમારી ભાષા પસંદ કરો",
              part4: "ચૂંટો",
              welcomeText:"રેલટેલ હાઇ-સ્પીડ વાઈફાઈ પર આપનું સ્વાગત છે",
              aboutUs:"અમારા વિશે માહિતી:",
              connect: "Wi-Fi થી કનેક્ટ કરો",
              contact:"સંપર્ક નં:",
              feedback: "સૂચના માટે,",
              click: "અહીં ક્લિક કરો",
              ad: "જાહેરાત માટે અમારી સાથે,",
              sendCode: "કોડ મોકલો",
              usernamePlace: "વપરાશકર્તા નામ",
              passworPlace: "પાસવર્ડ",
              submit:"સબમિટ કરો",
              login: "પ્રવેશ કરો",
              complaint: "કોઈ પણ ફરિયાદ / મુદ્દો માટે",
              email: "ગ્રાહક સેવા ઇમેઇલ",
              userLogin: "રેલટેલ વપરાશકર્તા લોગિન",
              connectWifi: "વાઈફાઈ સંપર્ક કરો",
              user: "રેલવાયર વપરાશકર્તા",
              userClick: "અહીં ક્લિક કરો",
              termsText: "જોડાવવાથી, તમે સંમત થાઓ છો",
              termsText2: "",
              termsClick: "નિયમો અને શરતો",
              irctcText: "વેબસાઇટ www.irctc.in એક્સેસ કરવા માટે",
              indiaRail: "વેબસાઇટ www.indiarail.gov.in એક્સેસ કરવા માટે",
              number: "મોબાઇલ નંબર દાખલ કરો *",
              phonePlace: "મોબાઇલ નંબર દાખલ કરો",
              otp: "OTP નંબર દાખલ કરો *",
              otpPlace:"OTP દાખલ કરો",
              changeNum: "મોબાઇલ નંબર બદલો",
              cmp1:"OTP મેળવ્યું નથી?",
              sec: "OTP સમય પૂર્તિ થઈ રહ્યું છે",
              otpExpire: "OTP સમય પૂર્તિ થઇ ગયો છે",
              otpResend: "OTP ફરીથી મોકલો",
              verify:"ચકાસો",
              freeWifi: "મફત વાઈફાઈ",
              freeSpeed: "30 મિનિટ, સામાન્ય સ્પીડ",
              paidWifi: "ભાડેની વાઈફાઈ",
              validity:"સુધી માન્ય યોજના",
              remaining:"બાકી",
              expired:"યોજના સમાપ્ત",
              plans:"યોજનાઓ જુઓ",

              paidSpeed: "હાઈ સ્પીડ",
              wifiConnect: "RailTel વાઈફાઈ સાથે કનેક્ટ થયો છે",
              wifiConnect1: "તમે 30 મિનિટ માટે મફત ઇન્ટરનેટ વપરાશ કરી શકો છો.",
              wifiConnect2: "તમારો દિવસ સુખદ બનશો !!!",
              footerText: "RailMitraa એપ્લીકેશન ડાઉનલોડ કરીને WiFi સુધી પહોંચો.",
              paidConnect: "તમારો પસંદ કરેલ પ્લાન 34Mbps 20GB/Days સક્રિય છે.",
              paidOptionText: "તમારો Wi-Fi સર્વિસ પ્લાન પસંદ કરો",
              option: "34Mbps 20GB/5 દિવસ",
              buy: "खरीदें",
              modalFooterText: 'નીચે આપેલા શરતો અંગેની શરતો સભેનો અંગ છે જે આ જગ્યાએ Railwire Wi-Fi સેવા પ્રાપ્ત કરવા માટે ગ્રાહક સ્વીકાર કરે છે. 1. "ગ્રાહક / ગ્રાહક" એની અર્થ કોઈ પણ વ્યક્તિ છે જે જગ્યાઓમાં રેલવેર સેવાઓ (ઓ)નું લાભ ઉઠાવે છે.',
              aboutUsText:"રેલટેલ કોર્પોરેશન ઑફ ઇન્ડિયા લિમિટેડ ('રેલટેલ'), રેલવે મંત્રાલયની અંતર્ગત એક સાર્વજનિક સેક્ટર ઉદ્યોગ છે, જેનું રજિસ્ટર્ડ કાર્યાલય પ્લેટ-એ, 6મી મહેનતા, ઓફિસ બ્લોક ટાવર-2, ઈસ્ટ કિડવાઈ નગર, ન્યૂ દિલ્હી- 110023, CIN: U64202DL2000GO | 107905 છે. રેલવાયર એક રેલટેલનું પહેલું છે. આ પહેલું હેતુ હેઠળની બધી સેવાઓ આ પહેલાંની પેશકશ તરીકે હિતેષ આપવામાં આવશે 'રેલવાયર ઑપરેશન્સ' દ્વારા અથવા ફક્ત 'રેલવાયર' દ્વારા પૂરી કરવામાં આવશે",
            }
          }
        },
        
        bgl: {
          translation: {
            description: {
              lang:"বাংলা",
              part1: "ব্যবহারকারীর নাম লিখুন",
              part2: "পাসওয়ার্ড লিখুন",
              part3: "আপনার ভাষা চয়ন করুন",
              part4: "নির্বাচন করুন",
              welcomeText:"রেলটেল হাই-স্পীড ওয়াই-ফাইতে স্বাগতম",
              aboutUs:"আমাদের সম্পর্কে:",
              connect:"ওয়াইফাই সংযুক্ত করুন",
              contact:"যোগাযোগ নম্বর:",
              contactNo:"+124-2714000",
              feedback:"মতামত জানানোর জন্য,",
              click:"এখানে ক্লিক করুন",
              ad:"আমাদের সাথে বিজ্ঞাপনের জন্য,",
              sendCode:"কোড পাঠান",
              usernamePlace: "ব্যবহারকারীর নাম",
              passworPlace: "পাসওয়ার্ড",
              submit:"জমা দিন",
              login: "লগইন",
              complaint: "যদি কোন অভিযোগ থাকে তাহলে",
              email: "গ্রাহক যোগাযোগ ইমেল",
              userLogin:"রেলটেল ব্যবহারকারীদের লগইন",
              connectWifi: "ওয়াইফাই সংযুক্ত করুন",
              user: "রেলওয়ে ব্যবহারকারী",
              userClick: "এখানে ক্লিক করুন",
              termsText: "সংযোগ করে, আপনি এর সাথে একমত হন",
              termsText2: "",
              termsClick: "শর্তাদি এবং নিয়ম",
              irctcText: "www.irctc.in এ প্রবেশ করতে",
              indiaRail: "www.indiarail.gov.in এ প্রবেশ করতে",
              number: "মোবাইল নম্বর লিখুন*",
              phonePlace: "মোবাইল নম্বর লিখুন",
              otp: "OTP নম্বর লিখুন*",
              otpPlace: "OTP নম্বর লিখুন",
              changeNum: "মোবাইল নম্বর পরিবর্তন করুন",
              cmp1: "OTP পেয়ে না?",
              sec: "OTP মেয়াদ উত্তীর্ণ হবে",
              otpExpire: "OTP মেয়াদ উত্তীর্ণ হয়ে গেছে",
              otpResend: "OTP পুনরায় পাঠান",
              verify: "যাচাই",
              freeWifi: "ফ্রি ওয়াইফাই",
              freeSpeed: "30 মিনিট, সাধারণ স্পীড",
              paidWifi: "পেইড ওয়াইফাই",
              validity:"পরিকল্পনা বৈধতা ",

              remaining:"অবশিষ্ট",
            expired:"পরিকল্পনার মেয়াদ শেষ",
             plans:"পরিকল্পনা দেখুন",

              paidSpeed: "উচ্চ স্পীড",
              wifiConnect: "RailTel ওয়াইফাইতে সংযুক্ত",
              wifiConnect1: "আপনি 30 মিনিটের জন্য ফ্রি ইন্টারনেট ব্যবহার করতে পারবেন।",
              wifiConnect2: "সুদিন কাটুক আর ভ্রমণ করুন !!!",
              paidConnect: "আপনার নির্বাচিত পরিকল্পনা 34 মেগাবিট/সেকেন্ড, 20 জিবি/দিন সক্রিয় হয়েছে।",
              paidOptionText: "আপনার ওয়াইফাই সেবা পরিকল্পনা নির্বাচন করুন",
              option: "34 মেগাবিট/সেকেন্ড, 20 জিবি/5 দিন",
              buy: "ক্রয় করুন",
              footerText: "প্রসারিত ওয়াইফাই অ্যাক্সেস এর জন্য RailMitraa অ্যাপটি ডাউনলোড করুন।",
              aboutUsText:"রেলটেল কর্পোরেশন অফ ইন্ডিয়া লিমিটেড ('রেলটেল'), রেলমন্ত্রণালয়ের অধীনে একটি পাবলিক সেক্টর উদ্যোগ (পিএসই) যা তার নিবন্ধিত অফিস প্লেট-এ, 6ষ্ঠ তলা, অফিস ব্লক টাওয়ার-২, ইস্ট কিডওয়াই নগর, নিউ দিল্লী- 110023, CIN : U64202DL2000GO|107905| এর বাস্তবায়ন করে। রেলওয়ায়ের একটি উদ্যোগ হিসাবে রেলওয়ায়ের সমস্ত পরিষেবা পরিচালিত হয় 'রেলওয়ায়ার অপারেশনস' বা কেবলমাত্র 'রেলওয়ায়ার' নামে।",
              modalFooterText:"নিচে উল্লিখিত শর্তাদি অংশগুলি এই স্থানে রেলওয়ায়ার ওয়াইফাই সেবার সুবিধা পেতে গ্রাহক/গ্রাহকের গ্রহণের অংশ হিসাবে গণ্য হয়। ১. 'গ্রাহক / গ্রাহক' মানে রেলওয়ায়ার সেবা (সমূহ) স্থানে রেলওয়ের সেবা পান যে কোনও ব্যক্তি।",
            },
          },
        },
      knd: {
        translation: {
          description: {
            lang:"ಕನ್ನಡ",
            welcomeText: "RailTel ಹೈ-ಸ್ಪೀಡ್ ವೈ-ಫೈ ಗೆ ಸುಸ್ವಾಗತ",
            part1: "ಬಳಕೆದಾರ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
            part2: "ಪಾಸ್ವರ್ಡ್ ನಮೂದಿಸಿ",
            part3: "ನಿಮ್ಮ ಭಾಷೆಯನ್ನು ಆರಿಸಿ",
            part4: "ಆಯ್ಕೆ ಮಾಡಿ",
            aboutUs:"ನಮ್ಮ ಬಗ್ಗೆ:",
            connect:"Wi-Fi ಗೆ ಸಂಪರ್ಕಿಸಿ",
            contact:"ಸಂಪರ್ಕ ಸಂಖ್ಯೆ:",
            contactNo:"+124-2714000",
            feedback:"ಫೀಡ್‌ಬ್ಯಾಕ್ ಗಾಗಿ,",
            click:"ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ",
            ad:"ನಮ್ಮೊಂದಿಗೆ ಜಾಹೀರಾತಿ ಮಾಡಲು,",          
            sendCode: "ಕೋಡ್ ಕಳುಹಿಸಿ",
            usernamePlace: "ಬಳಕೆದಾರ ಹೆಸರು",
            passworPlace: "ಗುಪ್ತಪದ",
            submit:"ಸಲ್ಲಿಸಿ",
            login: "ಲಾಗಿನ್ ಮಾಡಿ",
            complaint: "ಯಾವುದೇ ದೂರುಗಳು/ಸಮಸ್ಯೆಗಳಿಗೆ ",
            email: " ಗ್ರಾಹಕ ಆರೈಕೆ ಇಮೇಲ್",
            userLogin:"ರೈಲ್ಟೆಲ್ ಬಳಕೆದಾರರ ಲಾಗಿನ್",
            connectWifi: "Wi-Fi ಗೆ ಸಂಪರ್ಕಪಡಿಸಿ",
            user: "ರೈಲ್‌ವೈರ್ ಬಳಕೆದಾರ",
            userClick: "ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ",
            termsText: "ಸಂಪರ್ಕಿಸುವ ಮೂಲಕ ನೀವು ಒಪ್ಪುತ್ತೀರಿ",
            termsClick: "ನಿಯಮ ಮತ್ತು ಶರತ್ತುಗಳು",
            irctcText: "www.irctc.in ಅನ್ನು ಪ್ರವೇಶಿಸಲು",
            indiaRail: "www.indiarail.gov.in ಅನ್ನು ಪ್ರವೇಶಿಸಲು",
            number: "ಮಾನ್ಯವಾದ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
            phonePlace: "ದೂರವಾಣಿ ಸಂಖ್ಯೆ",
            otp: "OTP ನಮೂದಿಸಿ",
            otpPlace: "ಒಟಿಪಿ ಸ್ಥಳ",
            changeNum: "ಸಂಖ್ಯೆಯನ್ನು ಬದಲಾಯಿಸಿ",
            cmp1:"OTP ಪಡೆಯಲಿಲ್ಲವೆ?",
            sec: "OTP 60 ಸೆಕೆಂಡುಗಳಲ್ಲಿ ಮುಕ್ತಾಯಗೊಳ್ಳುತ್ತದೆ",
            otpExpire: "OTP ಅವಧಿ ಮುಗಿದಿದೆ",
            otpResend: "OTP ಅನ್ನು ಮರುಕಳುಹಿಸಿ",
            verify:"ಪರಿಶೀಲಿಸಿ",
            freeWifi: " ಉಚಿತ Wi-Fi",
            freeSpeed: "30 ನಿಮಿಷ, ಸಾಮಾನ್ಯ ವೇಗ",
            paidWifi: "ಪಾವತಿಸಿದ Wi-Fi",
            validity:"ವರೆಗೆ ಯೋಜನೆ ಮಾನ್ಯವಾಗಿರುತ್ತದೆ",
            remaining:"ಉಳಿದಿದೆ",
            expired:"ಯೋಜನೆ ಅವಧಿ ಮೀರಿದೆ",
            plans:"ಯೋಜನೆಗಳನ್ನು ವೀಕ್ಷಿಸಿ",
            paidSpeed: "ವೇಗದ ವೇಗ",
            wifiConnect: "RailTel ವೈಫೈಗೆ ಸಂಪರ್ಕಗೊಂಡಿದೆ",
            wifiConnect1: "ನೀವು 30 ನಿಮಿಷಗಳ ಕಾಲ ಉಚಿತ ಇಂಟರ್ನೆಟ್ ಅನ್ನು ಬಳಸಬಹುದು.",
            wifiConnect2: "ಶುಭ ದಿನ!!!",
            footerText:
              "ವಿಸ್ತೃತ ವೈಫೈ ಪ್ರವೇಶಕ್ಕಾಗಿ ದಯವಿಟ್ಟು RailMitraa ಅಪ್ಲಿಕೇಶನ್ ಅನ್ನು ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ.",
            paidConnect:
              "ನೀವು ಆಯ್ಕೆಮಾಡಿದ 34Mbps 20GB/ದಿನಗಳ ಯೋಜನೆ ಸಕ್ರಿಯವಾಗಿದೆ.",
            paidOptionText: "ನಿಮ್ಮ ವೈ-ಫೈ ಸೇವಾ ಯೋಜನೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
            option: "34Mbps 20GB/5 ದಿನಗಳು",
            buy: "ಖರೀದಿಸಿ",
            aboutUsText:"ರೈಲ್ವೈರ್ ಸೇವೆಗಳನ್ನು ರೈಲ್ಟೆಲ್ ಕಾರ್ಪೊರೇಷನ್ ಆಫ್ ಇಂಡಿಯಾ ಲಿಮಿಟೆಡ್ ('ರೈಲ್ಟೆಲ್'), ರೈಲ್ವೇ ಮಂತ್ರಾಲಯದ ಅಧಿಕಾರ ವಿಭಾಗದ ಪಬ್ಲಿಕ್ ಸೆಕ್ಟರ್ ಅಂಡರ್ಟೇಕಿಂಗ್ (ಪಿಎಸ್ಯು) ಆಗಿ, ಪ್ಲೇಟ್-ಎ, 6 ನೇ ಮಹಡಿ, ಆಫಿಸ್ ಬ್ಲಾಕ್ ಟವರ್-2, ಈಸ್ಟ್ ಕಿಡ್ವಾಯ್ ನಗರ, ನ್ಯೂ ದೆಹಲಿ - 110023, ಸಿಐಎನ್: U64202DL2000GO|107905 ಹೊಂದಿರುವುದು. ರೈಲ್ವೈರ್ ರೈಲ್ಟೆಲ್ ಯೋಜನೆಯು. ಈ ಯೋಜನೆಯ ಅಡಿಯಲ್ಲಿ ಒಂದಾದ ಎಲ್ಲಾ ಸೇವೆಗಳನ್ನು ರೈಲ್ವೈರ್ ಆಪರೇಷನ್ಸ್ ಅಥವಾ ಬಹುಳ ರೈಲ್ವೈರ್ ಎಂದು ಕರೆಯಲಾಗುತ್ತದೆ.",
            modalFooterText:"ಕೆಳಗಿನ ನಿಯಮಗಳು ಈ ಸ್ಥಳದಲ್ಲಿ ರೈಲ್‌ವೈರ್ ವೈ-ಫೈ ಸೇವೆಯನ್ನು ಲಭ್ಯವಿಡಲು ಗ್ರಾಹಕ ಒಪ್ಪಿಕೆಗೆ ಅಗತ್ಯವಿದೆ. 1. 'ಗ್ರಾಹಕ / ಗ್ರಾಹಕ' ಎಂದರೆ ಸ್ಥಳಗಳಲ್ಲಿ ರೈಲ್ವೈರ್ ಸೇವೆಗಳನ್ನು ಪಡೆದ ಯಾವ ವ್ಯಕ್ತಿಯೂ ಆಗಿದೆ.",
          },
        },
      },
      tlg: {
        translation: {
          description: {
            lang:"తెలుగు",
            welcomeText: "RailTel హై-స్పీడ్ వై-ఫైలో స్వాగతం",
            part1: "వినియోగదారు పేరును నమోదు చేయండి",
            part2: "రహస్య సంకేతం తెలపండి",
            part3: "మీ భాషను ఎంచుకోండి",
            part4: "ఎంచుకోండి",
            aboutUs:"మా గురించి:",
            connect:"Wi-Fi కు కనెక్ట్ అవ్వండి",
            contact:"కాంటాక్ట్ నంబర్:",
            contactNo:"+124-2714000",
            feedback:"ఫీడ్‌బ్యాక్ కోసం,",
            click:"ఇక్కడ క్లిక్ చేయండి",
            ad:" మాతో వెల్లడించే వెంటనే,",         
            sendCode: "కోడ్ పంపండి",
            usernamePlace: "వినియోగదారు పేరు",
            passworPlace: "పాస్వర్డ్",
            submit:"సబ్మిట్ చేయి",
            login: "ప్రవేశించండి",
            complaint: "ఏదైనా ఫిర్యాదులు/సమస్యల కోసం ",
            email: " కస్టమర్ కేర్ ఇమెయిల్",
            userLogin:"రైల్ టెల్ యూజర్ల లాగిన్",
            connectWifi: "Wi-Fiకి కనెక్ట్ చేయండి",
            user: "RailWire వినియోగదారు",
            userClick: "ఇక్కడ నొక్కండి",
            termsText: "కనెక్ట్ చేయడం ద్వారా మీరు దీనికి అంగీకరిస్తున్నారు",
            termsClick: "నిబంధనలు & షరతులు",
            irctcText: "www.irctc.inని యాక్సెస్ చేయడానికి",
            indiaRail: "www.indiarail.gov.inని యాక్సెస్ చేయడానికి",
            number: "చెల్లుబాటు అయ్యే మొబైల్ నంబర్‌ను నమోదు చేయండి",
            phonePlace: "ఫోను నంబరు",
            otp: "OTPని నమోదు చేయండి",
            otpPlace: "ఓటిపి ప్లేస్",
            changeNum: "సంఖ్యను మార్చండి",
            cmp1:"OTP పొందలేదు?",
            sec: "OTP గడువు 60 సెకన్లలో ముగుస్తుంది",
            otpExpire: "OTP గడువు ముగిసింది",
            otpResend: "OTP ను మళ్ళీ పంపు",
            verify:"ప్రమాణించండి",
            freeWifi: " ఉచిత వైఫై",
            freeSpeed: "30 నిమి, సాధారణ వేగం",
            paidWifi: "చెల్లించిన Wi-Fi",
            validity:"వరకు ప్లాన్ చెల్లుతుంది ",
            remaining:"మిగిలినవి",
            expired:"ప్లాన్ గడువు ముగిసింది",
            plans:"ప్రణాళికలను వీక్షించండి",
            paidSpeed: "వేగవంతమైన వేగం",
            wifiConnect: "RailTel WiFiకి కనెక్ట్ చేయబడింది",
            wifiConnect1:
              "మీరు 30 నిమిషాల పాటు ఉచిత ఇంటర్నెట్‌ని ఉపయోగించవచ్చు.",
            wifiConnect2: "ఈ రోజు మీకు కుశలంగా ఉండును!!!",
            footerText:
              "పొడిగించిన WiFi యాక్సెస్ కోసం దయచేసి RailMitraa యాప్‌ని డౌన్‌లోడ్ చేసుకోండి.",
            paidConnect:
              "మీరు ఎంచుకున్న 34Mbps 20GB/రోజుల ప్లాన్ సక్రియంగా ఉంది.",
            paidOptionText: "మీ Wi-Fi సర్వీస్ ప్లాన్‌ని ఎంచుకోండి",
            option: "34Mbps 20GB/5 రోజులు",
            buy: "కొనుగోలు",
            aboutUsText:"రైల్ వైర్ సేవలు రైల్టెల్ కార్పొరేషన్ ఆఫ్ ఇండియా లిమిటెడ్. ('రైల్ టెల్), రైల్వేస్ మంత్రాలయంలో పబ్లిక్ సెక్టర్ అండర్టేకింగ్ (PSU), ప్లేట్-ఎ, 6వ మంత్రివాడి టవర్-2, ఈస్ట్ కిడ్వాయ్ నగర్, న్యూ దిల్లీ- 110023, CIN : U64202DL2000GO|107905. రైల్ వైర్ రైల్ టెల్ యొక్క ఒక ప్రయత్నం. ఈ ప్రయత్నం అండికి అందుబాటులో ఉన్న అన్ని సేవలు 'రైల్వేయిర్ ఆపరేషన్స్' ద్వారా అందిస్తుంది లేదా కేవలం 'రైల్వేయిర్' ద్వారా అందిస్తుంది.",
            modalFooterText:"ఈ వెన్యూలో రైల్వెయిర్ వై-ఫై సేవను పొందడానికి వినియోగదారు అంగీకరణను కలిగి ఉండాలి. 1. 'కస్టమర్ / సబ్‌స్క్రైబర్' అని అర్థం స్థానాల్లో రైల్వెయిర్ సేవలను పొందిన ఎవరైనా వ్యక్తి ఉంటే.",
          },
        },
      },
      tml: {
        translation: {
          description: {
            lang:"தமிழ்",
            welcomeText: "RailTel உயர் வேக வாய்-ஃபையில் வருகையேற்பு",
            part1: "பயனர்பெயரை உள்ளிடவும்",
            part2: "கடவுச்சொல்லை உள்ளிடவும்",
            part3: "உங்கள் மொழியை தேர்வு செய்யவும்",
            part4: "தேர்ந்தெடு",
            aboutUs:"எங்களை பற்றி:",
            connect:"Wi-Fi-ஐ இணைக்கவும்",
            contact:"தொடர்பு எண்:",
            contactNo:"+124-2714000",
            feedback:"கருத்துக்களுக்கு,",
            click:"இங்கே கிளிக் செய்யவும்",
            ad:"எங்குடன் விளம்பரம் செய்ய வேண்டும்,",          
            sendCode: "குறியீடு அனுப்பு",
            usernamePlace: "பயனர் பெயர்",
            passworPlace: "கடவுச்சொல்",
            submit:"சமர்ப்பிக்கவும்",
            login: "உள்நுழைய",
            complaint: "ஏதேனும் புகார்கள்/சிக்கல்களுக்கு ",
            email: " வாடிக்கையாளர் பராமரிப்பு மின்னஞ்சல்",
            userLogin:"ரெயில்டெல் பயனர் உள்நுழைய",
            connectWifi: "Wi-Fi உடன் இணைக்கவும்",
            user: "RailWire பயனர்",
            userClick: "இங்கே கிளிக் செய்யவும்",
            termsText: "இணைப்பதன் மூலம் நீங்கள் ஒப்புக்கொள்கிறீர்கள்",
            termsClick: "விதிமுறைகளும் நிபந்தனைகளும்",
            irctcText: "www.irctc.in ஐ அணுகவும்",
            indiaRail: "www.indiarail.gov.in ஐ அணுகவும்",
            number: "செல்லுபடியாகும் மொபைல் எண்ணை உள்ளிடவும்",
            phonePlace: "தொலைபேசி எண்",
            otp: "OTP ஐ உள்ளிடவும்",
            otpPlace: "ஓடிபி இருப்பிடம்",
            changeNum: "எண்ணை மாற்றவும்",
            cmp1:"OTP கிடைக்கவில்லையா?",
            sec: "OTP 60 வினாடிகளில் காலாவதியாகும்",
            otpExpire: "OTP காலாவதியானது",
            otpResend: "OTP ஐ மீண்டும் அனுப்பவும்",
            verify:"சரிபார்க்கவும்",
            freeWifi: " இலவச இணைய வசதி",
            freeSpeed: "30 நிமிடம், சாதாரண வேகம்",
            paidWifi: "கட்டண Wi-Fi",
            validity:"வரை செல்லுபடியாகும் திட்டம்",
            remaining:"மீதமுள்ள",
            expired:"திட்டம் காலாவதியானது",
            plans:"திட்டங்களைக் காண்க",
            paidSpeed: "வேகமான வேகம்",
            wifiConnect: "RailTel வைஃபையுடன் இணைக்கப்பட்டது",
            wifiConnect1:"நீங்கள் 30 நிமிடங்களுக்கு இலவச இணையத்தைப் பயன்படுத்தலாம்.",
            wifiConnect2: "இந்த நாள் இனிதாகட்டும்!!!",
            paidConnect:"நீங்கள் தேர்ந்தெடுத்த 34Mbps 20GB/நாள் திட்டம் செயலில் உள்ளது.",
            paidOptionText: "உங்கள் வைஃபை சேவைத் திட்டத்தைத் தேர்ந்தெடுக்கவும்",
            option: "34Mbps 20GB/5 நாட்கள்",
            buy: "வாங்க",
            footerText:"நீட்டிக்கப்பட்ட வைஃபை அணுகலுக்கு, RailMitraa பயன்பாட்டைப் பதிவிறக்கவும்.",
            aboutUsText:"ரெயில்வைர் சேவைகள் ரெயில்டெல் கார்ப்பரேஷன் ஆஃப் இந்தியா லிட். ('ரெயில்டெல்), ரயில்வே அமைச்சரவுக்கு ஒரு பொது வகுப்பின் கீழ் உள்ள ஒரு பொது நிறுவனமாகும், பிளேட்-ஏ, 6வது மாடியில், ஆபீஸ் பிளாக் டவர்-2, கிட்வை நகர், நியூ டெல்ஹி - 110023, சிஐஎன்: U64202DL2000GO | 107905. ரெயில்வைர் ஒரு ரெயில்டெல் திட்டத்தின் ஒரு முறையாகும். இந்த திட்டத்தின் கீழ் வழங்கப்படும் அனைத்து சேவைகளும் உருவாக்கப்பட்ட முறையினால் மட்டுமே 'ரெயில்வைர் ஓபரேஷன்ஸ்' என அழைக்கப்படும் அல்லது எளிதில் 'ரெயில்வைர்' என அழைக்கப்படும்",
            modalFooterText:"கீழே உள்ள நிபந்தனைகள் ரெயில்வைர் வாலை சேவையை இந்த இடத்தில் பயன்படுத்துவதற்கான உறுதிப்படுத்தலின் ஒரு முழுத் துணைத்துவமாகும். 1. வாடிக்கையாளர் எந்த இடத்தில் ரெயில்வைர் சேவைகளை (கடைகளில்) பயன்படுத்துகின்றார் என்றால் 'வாடிக்கையாளர் / சந்தாதா' என்பது பொருத்தப்படும்.",
          },
        },
      },
      mlym: {
        translation: {
          description: {
            lang:"മലയാളം",
            welcomeText:"RailTel ഹൈ-സ്പീഡ് വൈ-ഫൈയിലേക്ക് സ്വാഗതം",
            part1: "ഉപയോക്തൃനാമം നൽകുക",
            part2: "പാസ്‌വേഡ് നൽകുക",
            part3: "നിങ്ങളുടെ ഭാഷ തിരഞ്ഞെടുക്കുക",
            part4: "തിരഞ്ഞെടുക്കുക",
            aboutUs:"ഞങ്ങളെപ്പറ്റി:",
            connect:"Wi-Fi കണക്റ്റ് ചെയ്യുക",
            contact:"ബന്ധപ്പെടാനുള്ള നമ്പർ:",
            contactNo:"+124-2714000",
            feedback:"ഫീഡ്ബാക്ക് ലഭിക്കുന്നതിന്,",
            click:"ഇവിടെ ക്ലിക്ക് ചെയ്യുക",
            ad:"ഞങ്ങളോടൊപ്പം പരസ്യം ചെയ്യുന്നതിനായി,",         
            sendCode: "കോഡ് അയയ്ക്കുക",
            usernamePlace: "ഉപയോക്തൃനാമം",
            passworPlace: "പാസ്‌വേഡ് ",
            submit:"സമർപ്പിക്കുക", 
            login: "ലോഗിൻ",
            complaint: "ഏത് പരാതികൾക്കും/പ്രശ്നങ്ങൾക്കും ",
            email: " കസ്റ്റമർ കെയർ ഇമെയിൽ",
            userLogin:"റെയിൽടെൽ ഉപയോക്തൃ ലോഗിൻ",
            connectWifi: "വൈഫൈയിലേക്ക് കണക്‌റ്റ് ചെയ്യുക",
            user: "റെയിൽവയർ ഉപയോക്താവ്",
            userClick: "ഇവിടെ ക്ലിക്ക് ചെയ്യുക",
            termsText: "ബന്ധിപ്പിക്കുന്നതിലൂടെ നിങ്ങൾ അംഗീകരിക്കുന്നു.",
            termsClick: "നിബന്ധനകളും വ്യവസ്ഥകളും",
            irctcText: "www.irctc.in എന്ന വെബ്സൈറ്റിലേക്ക് പ്രവേശിക്കുക",
            indiaRail: "www.indiarail.gov.in എന്ന വെബ്‌സൈറ്റിൽ പ്രവേശിക്കുക",
            number: "സാധുവായ ഒരു മൊബൈൽ നമ്പർ നൽകുക",
            phonePlace: "ഫോൺ നമ്പർ",
            otp: "OTP നൽകുക",
            otpPlace: "ഒടിപി സ്ഥലം",
            changeNum: "നമ്പർ മാറ്റുക",
            cmp1:"OTP ലഭിച്ചിട്ടില്ലേ?",
            sec: "OTP 60 സെക്കൻഡിനുള്ളിൽ കാലഹരണപ്പെടും",
            otpExpire: "OTP കാലഹരണപ്പെട്ടു",
            otpResend: "OTP വീണ്ടും അയയ്‌ക്കുക",
            verify:"പരിശീലിക്കുക",
            freeWifi: " സൗജന്യ Wifi",
            freeSpeed: "30 മിനിറ്റ്, സാധാരണ വേഗത",
            paidWifi: "പണമടച്ചുള്ള Wi-Fi",
            validity:"വരെ സാധുതയുള്ള പ്ലാൻ",
            remaining:"ശേഷിക്കുന്നത്",
            expired:"പ്ലാൻ കാലഹരണപ്പെട്ടു",
            plans:"പ്ലാനുകൾ കാണുക",
            paidSpeed: "ഉയർന്ന വേഗത",
            wifiConnect: "RailTel വൈഫൈയിലേക്ക് കണക്‌റ്റ് ചെയ്‌തു",
            wifiConnect1:"നിങ്ങൾക്ക് 30 മിനിറ്റ് സൗജന്യ ഇന്റർനെറ്റ് ഉപയോഗിക്കാം.",
            wifiConnect2: "ഒരു നല്ല ദിവസം വരട്ടെ!!!",
            paidConnect: "നിങ്ങൾ തിരഞ്ഞെടുത്ത പ്ലാൻ 34Mbps 20GB/Days സജീവമാണ്.",
            paidOptionText: "നിങ്ങളുടെ Wi-Fi സേവന പ്ലാൻ തിരഞ്ഞെടുക്കുക",
            option: "34Mbps 20GB/5 ദിവസം",
            buy: "വാങ്ങാൻ",
            footerText:"വിപുലീകൃത വൈഫൈ ആക്‌സസിന് ദയവായി RailMitraa ആപ്പ് ഡൗൺലോഡ് ചെയ്യുക.",
            aboutUsText:"റെയിൽടെൽ കോർപറേഷൻ ഓഫ് ഇന്ത്യ ലിമിറ്റഡി ( 'റെയിൽടെൽ), റെയിൽവേ മന്ത്രാലയത്തിന്റെ ഒരു പബ്ലിക് സെക്റ്റർ ഉണ്ടാക്കിയ ഒരു ഉദ്യമം ആണ്, പ്ലേറ്റ്-എ, 6-ാം ഫ്ലോർ, ഓഫീസ് ബ്ലോക്ക് ടവർ-2, ഇസ്റ്റ് കിഡ്വായ് നഗർ, ന്യൂ ഡൽഹി - 110023, സിഐഎൻ : യു64202ഡിഎൽ2000ജിഒ | 107905. റെയിൽവയർ റെയിൽടെൽന്റെ ഒരു ഇനിറ്റിയേറ്റിവ് ആണ്. ഈ ഇനിറ്റിയേറ്റിവിന് അടിസ്ഥാനമാക്കി നൽകിയ എല്ലാ സേവനങ്ങൾക്കും “റെയിൽവയർ ഓപ്പറേഷനുകളായി” അല്ലെങ്കിൽ പ്രത്യേകിച്ച് “റെയിൽവയർ” എന്നു പറയുന്നതാണ്.",
            modalFooterText:"താഴെ പറഞ്ഞ നിബന്ധനകൾ ഈ സ്ഥലത്ത് റെയിൽവെയർ വായ്-ഫൈ സേവനം ഉപയോഗിക്കുന്നതിന്റെ ഒരു പാർട്ടാക്കമാകുന്നു. 1. 'കസ്റ്റമർ / സബ്സ്ക്രൈബർ' എന്നാൽ കടകളിൽ റെയിൽവെയർ സേവനങ്ങൾ ഉപയോഗിക്കുന്ന ഏതെങ്കിലും വ്യക്തിയാണ്.",
          },
        },
      },
    },
  });

export default i18n;
