import React, { useState,lazy, useContext } from "react";
import "../../src/main.css";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "video-react/dist/video-react.css";
import { BaseURL } from "../common/baseUrl";
import {AdContext, AdIdContext} from './adManagerContext'
const LogoComponent = lazy(() => import('./logoComponent'));
const FooterComponent = lazy(() => import('./footer'));

const UserLogin = () => {
  const { t } = useTranslation();
  // eslint-disable-next-line
  const {addManager, setAddManager} = useContext(AdContext);
  const {locationPath} = useContext(AdIdContext);
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const reqBody = {
    name: "",
    mobileno: number,
    macAddr: "",
    address: "",
    pinCode: "",
    idProofType: "",
    idProofNum: "",
    userIp: "",
    nasIp: "",
    password: password,
    customerId: "",
    nasPortId: "",
    sessionid: "",
    isNewMac: false,
  };

  const checkUserExists = async () => {
    await fetch(`${BaseURL}/UserRegistration/commonflows/isUserExistsCommFlow`, {
      method: "POST",
      body: JSON.stringify(reqBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("send sms data", data.success);
        if(data.success === true){
          navigate("/selectPaidOptions");
        }else{
          alert(data.msg)
        }
      })
      .catch((err) => {
        console.log(err.messsage);
      });
  };

  const handleSubmit = (e) => {
    checkUserExists();
  };

  return (
    <div className="header-main">
      <LogoComponent />
      <div className="p-num1">
        <Form>
          <Form.Group className="first-name-text" controlId="formBasicPassword">
            <Form.Label className="label-form2">{t('description.usernamePlace')}*</Form.Label>
            <Form.Control
              placeholder={t('description.part1')}
              value={number}
              type="text"
              onChange={(e) => {
                setNumber(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="email-text1" controlId="formBasicEmail">
            <Form.Label className="label-form2">{t('description.passworPlace')}*</Form.Label>
            <Form.Control
              type="password"
              placeholder={t('description.part2')}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Form.Group>
        </Form>
        <Button
          className="enter-btn"
          onClick={() => {
            handleSubmit();
          }}
        >
         {t('description.submit')}
        </Button>
      </div>
      <div className="video-ad">
      {addManager && addManager.length && addManager?.find(itm => itm.adSopt === "72622a54")?.isEnable === true ?(
         <div id ="ad_container2"><ins id="a2" data-meta={JSON.stringify(locationPath)} data-adspot-key="72622a54"></ins></div>): null
        } 
      </div>
      <div style={{ marginTop: "70px" }}>
        <FooterComponent />
      </div>
    </div>
  );
};

export default UserLogin;
