import platform from "platform";
import { useTranslation } from "react-i18next";
import React, { useState , lazy,useContext, useEffect } from "react";
import "../../src/main.css";
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "video-react/dist/video-react.css";
import { localhostURL } from "../common/baseUrl";
import moment from "moment/moment";
import DeviceInfo from 'react-native-device-info';
import {deviceDetect,mobileModel} from "react-device-detect";
import { v4 as uuid } from 'uuid';
import {InsAdSopt} from './ad3i';
import Logo from "./logoComponent";
import { AdContext, AdIdContext } from "./adManagerContext";
import { userVisit, setSessionFn } from '../common/commanfn';

const FooterComponent = lazy(() => import('./footer'));

const OtpComponent = () => {
  const { t } = useTranslation();
  const {addManager, setAddManager} = useContext(AdContext);
  const [ad1, setAd1] = useState(addManager?.find(itm => itm.ad_container=="CP_Enter_OTPTop"));
  const [ad2, setAd2] = useState(addManager?.find(itm => itm.ad_container=="CP_Enter_OTPCenter"));
  const [ad3, setAd3] = useState(addManager?.find(itm => itm.ad_container=="CP_Enter_OTPBottom"));
  // eslint-disable-next-line
  const {locationPath, bunConfig} = useContext(AdIdContext); 
  const configTimeout = bunConfig[0]?.CP_Enter_OTP;
  const [seconds, setSeconds] = useState(configTimeout)
  var rOTPTimeOut= 30;
  const [rOTP, setROTP] = useState(rOTPTimeOut);
	useEffect(() => {
		const interval = setInterval(() => {
			setSeconds(seconds=> seconds-1)
		}, 1000);
		if(seconds < 0){
			clearInterval(interval)
		}
	}, [configTimeout]);
  useEffect(() => {
    const intervalProcess = setInterval(() => {
      console.log("resendOtpTimeOut", rOTP)
			setROTP(rOTP=> rOTP - 1)
		}, 1000);
		if(rOTP < 0){
			clearInterval(intervalProcess)
		}
	}, [rOTPTimeOut]);
  useEffect(() =>{
    setAd1(addManager?.find(itm => itm?.ad_container === "CP_Enter_OTPTop"));
    setAd2(addManager?.find(itm => itm?.ad_container === "CP_Enter_OTPCenter"));
    setAd3(addManager?.find(itm => itm?.ad_container === "CP_Enter_OTPBottom"));
  },[addManager])

  const navigate = useNavigate();
  const [OTP, setOTP] = useState("");
  const [deviceId, setDeviceId] = useState('');
  const number = JSON.parse(localStorage.getItem("phoneNumber"));
  const custData= JSON.parse(localStorage.getItem("cusData"));
  const macAdress=window.localStorage.getItem("userMac");
  const userValid = localStorage.getItem("user");
  let getDate = new Date();
  let formattedGetDate = moment(getDate).format("YY-MM-DD");
  let formattedGetTime = moment(getDate).format("hh:mm:ss a");
  let platformOs = platform.os.family;
  let platformOsVersion = platform.os.version;
  const unique_id = uuid();

  DeviceInfo.getUniqueId().then(uniqueId => {
   
  });
 
const otpReqBody = {
  name: "",
  mobileno: number,
  macAddr: macAdress,
  address: "",
  os_version:platformOsVersion,
  mobile_make: deviceDetect().vendor,
  mobile_model: mobileModel,
  rec_date:formattedGetDate,
  start_time:formattedGetTime,
  pinCode: "",
  idProofType: "",
  idProofNum: "",
  userIp: "",
  nasIp: "172.31.32.62",
  password: "",
  customerId: "",
  nasPortId: "ae3:2028",
  mac_id: macAdress,
  sessionid: "",
  isNewMac: false,
   accountType: 3,
  createledger: true,
  validateOTP: OTP,
};
  const reqBody = {
    name: "",
    mobileno: number,
    firstname:"",
    middlename:"",
    lastname:"",
    address_1:"",
    address_2:"",
    address_3:"",
    pincode:"",
    city:"",
    state:"",
    age:"",
    gender:"",
    mobile_operator:"",
    operating_system:platformOs,
    rec_date:formattedGetDate,
    start_time:formattedGetTime,
    network: "",
    macAddr: macAdress,
    os_version:platformOsVersion,
    browser: platform.name,
    browser_ver: platform.version,
    imei:"",
    mobile_make: deviceDetect().vendor,
    mobile_model: mobileModel,
    ssid:"",
    idProofType: "",
    idProofNum: "",
    userIp: "",
    nasIp: "172.31.32.62",
    mac_id: macAdress,
    password: "",
    customerId: "",
    nasPortId: "ae3:2028",
    sessionid: "",
    isNewMac: false,
    wifi_mode:"",
    email:"",
    gstin:"",
    payment_gateway:"",
    package:"",
    amount:"",
    accountType: 3,
    createledger: true,
    validateOTP: null,
    otp_required:true
  };
  // create new user data

  // call check otp api
  const checkOtp = async () => {
    const cudtomerData= JSON.parse(localStorage.getItem("cusData"));
    otpReqBody.macAddr = cudtomerData.mac_id;
    setDeviceId(cudtomerData.mac_id);
    const postData={
      otpReqBody: otpReqBody,
      custdata: cudtomerData
    };
    
    await fetch(`${localhostURL}/api/v1/radius/validateSMS`,
      {
        method: "POST",
        body: JSON.stringify(postData),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((usedata) => {
        if (
          usedata.response.msg == "Invalidate OTP" &&
          usedata.response.success == false
        ) {
          //validatesub();
          alert('Invalid OTP.Please re-enter a valid OTP')
        } else if(usedata.response.msg == "user already exists with this account" &&
        usedata.response.success == false){
          //alert('User Authenticated');
          //navigate("/selectWifi");
           navigate("/activeFreewifiPlan");
        }else{
          if( usedata.response.success == true){
            //alert('User Authenticated');
           // navigate("/selectWifi");
           navigate("/activeFreewifiPlan");
          }
        }
      })
      .catch((err) => {
        console.log(err.messsage);
      });
  };

  // call send otp for resedn otp
  const sendSms = async () => {
    const cudtomerData= JSON.parse(localStorage.getItem("cusData"));
    otpReqBody.macAddr = cudtomerData.mac_id;
    const postData={
      reqBody: reqBody,
      custdata: cudtomerData
    };

    await fetch(`${localhostURL}/api/v1/radius/resendOTP `, 
      {
      
      method: "POST",
      body: JSON.stringify(postData),
    
      headers: {
        "Content-Type": "application/json",
      },
     
    })
      .then((response) => response.json())
      .then((data) => {
        //alert("OTP sent successfully!");
       
      })
      .catch((err) => {
        console.log(err.messsage);
      });
  };

 // call validate subscription
 const validatesub = async () => {
  await fetch(
    `$${localhostURL}/UserRegistration/commonflows/showQuotaAndValidityForPaidPlanCommFlow `, 
    {
    
    method: "POST",
    body: JSON.stringify(reqBody),
   
    headers: {
      "Content-Type": "application/json",
    },
    
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
    })
    .catch((err) => {
      console.log(err.messsage);
    });
};

const handleSubmit = (e) => {
  
  checkOtp();
  
};
  const handleSubmitResend = (e) => {
    sendSms();
   
  };
  const changeNumber = (e) => {
    navigate("/phoneNumber");
  }
  useEffect(() => { 
    setSessionFn();
    userVisit({otp_page: 1});
   },[]);

  return (
    <div className="header-main">
      <Logo adSoptData={ad1} locationPath={locationPath}/>
      <div className="video-ad">
      <InsAdSopt adSoptData={ad2} locationPath={locationPath}/> 
      </div>
      {/* enter phone number */}
      {custData.otp_required && custData.otp_required == true ?
      (<div className="p-num">
        <Form.Group className="email-text" controlId="formBasicEmail">
          <Form.Label className="label-form">
            <span className="otp-num">{t("description.otp")}</span>
            <span
              className="cmp"
              onClick={() => {
                
                changeNumber();
              }}
            >
              {t("description.changeNum")}
            </span>
          </Form.Label>
          <Form.Control
            type="number"
            placeholder={t("description.otpPlace")}
            value={OTP}
            onChange={(e) => {
              setOTP(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Label className="label-form1">
          <span className="cmp1">{t("description.cmp1")}</span>
          <button
            className="res-o"
            onClick={() => {
              handleSubmitResend();
              setROTP(30)
            }}
            disabled={rOTP > 0}
          >
            {t("description.otpResend") } {(rOTP > 0) ? rOTP : '' }
          </button>
        </Form.Label>
        <div className="btn-header">
        <Button
          className="verify-btn"
          onClick={() => {
            handleSubmit();
          }}
          disabled={seconds > 0}
        >
          {t("description.verify")}
          {seconds > 0  ?  <>
              <div className="spinner-grow"  role="status">...</div>
            <>{seconds}</>
          </> : null}
        </Button>
      </div></div>):<div><p> {t("description.validate")}</p></div>}
      
      {/* <div className="link-user">
        <span
          className="links-a-user1"
          onClick={() => {
            navigate("/user");
          }}
        >
          {t("description.userLogin")}
        </span>
      </div> */}
      <div></div>
      <div className="bottom-ads2 mx-auto">
      <InsAdSopt adSoptData={ad3} locationPath={locationPath}/> 
      </div>
      
      {/* footer links  */}
      <div className="foot-div">
        <FooterComponent />
      </div>
    </div>
  );
};

export default OtpComponent;
