import React, { useState,useEffect,lazy} from "react";
import "../../src/main.css";
import { Button } from "react-bootstrap";
import { useNavigate,useSearchParams } from "react-router-dom";
import "video-react/dist/video-react.css";
import { useTranslation } from "react-i18next";
import { localhostURL } from "../common/baseUrl";
const LogoComponent = lazy(() => import('./logoComponent'));
const FooterComponent = lazy(() => import('./footer'));

const Success = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [apiResponse, setApiResponse] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const paymentData =JSON.parse(window.localStorage.getItem("dataPayment"));
  const customerData= JSON.parse(localStorage.getItem("cusData"));
  const payAddData = window.localStorage.getItem("payformBody");
  console.log("payAddData", payAddData)
  const number = JSON.parse(localStorage.getItem("phoneNumber"));
  const cusAddData=window.localStorage.getItem("cusDeatilsData");
  const macAdress=window.localStorage.getItem("userMac");
  const useIp= window.localStorage.getItem("userIp");
  const nasId= window.localStorage.getItem("nasId");
  const sesId= window.localStorage.getItem("sessionId");
  const nasportId= window.localStorage.getItem("nasPortId");
  const orderData= JSON.parse(window.localStorage.getItem("orderDetails"));
  const orderID= searchParams.get("orderId")
  console.log("orderID------",orderID);
  // /callBackFromPG
  const reqData={
    orderId: orderID
  };
  const updateSaveBody = {
      name:cusAddData?.firstname,
      mobileno: number,
      macAddr: macAdress,
      address: cusAddData?.address,
      pinCode: payAddData?.additional_info_4,
      idProofType: "",
      idProofNum: "",
      userIp: useIp,
      nasIp: nasId,
      password: "",
      customerId: "",
      nasPortId: nasportId,
      sessionid: sesId,
      isNewMac: false,
      accountType: 3,
      createledger: true,
      pkgNo: 1,
      payment_gateway: apiResponse?.txn_type,
      package:paymentData?.id,
      amount : apiResponse?.txn_amount,
      firstname : cusAddData?.firstname,
      address_1 : "",
      email:cusAddData?.email,
      package_number:1,
      id:customerData?.id  
  }
  const fetchOrderData = async () => {
    await fetch(`${localhostURL}/api/v1/radius/fetchOrderDetail`, {
      method: "POST",
      body: JSON.stringify(reqData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Order Details Data-------", data.response[0]);
        setApiResponse(data.response[0])
        window.localStorage.setItem("orderDetails",JSON.stringify(data.response[0]));
      })
      .catch((err) => {
        console.log(err.messsage);
      });
  };
  const saveUpdatePiadRCount = async () => {
    const postData = {
      reqBody: updateSaveBody,
      custdata: customerData
    }
    await fetch(`${localhostURL}/api/v1/radius/saveUpdatePaidRechargeCount`, {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("SAVE UPDATE Details Details Data-------", data);
      })
      .catch((err) => {
        console.log(err.messsage);
      });
  };
  
  useEffect(() =>{
    fetchOrderData();
  },[])
  const navigatePaidWifi =()=>{
    saveUpdatePiadRCount();
    navigate("/paidwifiConnected");
  }
  
  return (
    <div className="header-main">
      <LogoComponent />
     {apiResponse.auth_status == "0300" ? ( 
      <div className="payment-success">
        <span>{t("description.paytitlesuc")}</span>{" "}
       </div>) 
      // <div className="img-suc">
      //   <img src={Imgs.suc} alt="" srcset="" />
      // </div>
           
           : ( 
   
     <div className="payment-failure">
        <span>{t("description.paytitlefail")}</span>{" "}
      </div>
      )}
       {/* {orderData.auth_status != "0300" && ( 
      <div className="img-suc">
        <img src={Imgs.failcross} alt="" srcset="" />
      </div>
           )} */}
      <div className="suc-body">
        <div className="text-success1">
          <span>{t("description.amountPaid")}</span>
          <span>{orderData?.txn_amount}</span>
        </div>
        <div className="text-success2">
          <span>{t("description.tId")}</span>
          <span>{orderData?.transaction_ref_no}</span>
        </div>
        <div className="text-success2">
          <span>{t("description.payMethod")}</span>
          <span>{orderData?.txn_type}</span>
        </div>

        <div className="text-success2">
          <span>{t("description.bankId")}</span>
          <span>{orderData?.bank_id}</span>
        </div>
        <div className="text-success2">
          <span>{t("description.reason")}</span>
          <span>{orderData?.error_desc}</span>
        </div>
        <div className="text-success2">
          <span>{t("description.dnt")} </span>
          <span>{orderData?.txn_datetime}</span>
        </div>
      </div>

      <div className="wifi-btns-suc">
        <Button
          variant="primary"
          className="wifi-paid1"
          onClick={() => window.print()}
        >
          {t("description.print")}
        </Button>
        {orderData?.auth_status == "0300" ? ( 
        <Button
          variant="primary"
          className="wifi-free1"
          onClick={() => navigatePaidWifi() }
        >
           {t("description.continue")}
        </Button> ): (
          
          <Button
          variant="primary"
          className="wifi-free1"
          onClick={() => navigate("/payment")}
        >
          {t("description.close")}
        </Button>
        )}
      </div>

      <div>
        <FooterComponent />
      </div>
    </div>
  );
};

export default Success;
