import React, { useState } from 'react';

const DyImage = ({ src, placeholder, metaData, alt }) => {
  const [error, setError] = useState(false);

  const handleImageError = () => {
    setError(true);
  };

  return (
    <>
      {error ? (
        <img src={placeholder} data-meta={JSON.stringify(metaData)} data-logs={src} alt={alt} className="first-img2 img-fluid"/>
      ) : (
        <img src={src} alt={alt} data-meta={JSON.stringify(metaData)} onError={handleImageError} className="first-img2 img-fluid"/>
      )}
    </>
  );
};

export default DyImage;
