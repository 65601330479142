import React, { useState , useEffect } from "react";
import "../../src/main.css";
import Imgs from "../common/imgImports";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { MdOutlineCheckCircleOutline } from "react-icons/md";
import { useTranslation } from "react-i18next";
import {InsAdSopt} from './ad3i';  
import { BaseURL, localhostURL } from "../common/baseUrl";
import {deviceDetect,mobileModel,isAndroid,OsTypes,osName, isIOS, isBrowser,isDesktop} from "react-device-detect";
const lngs = {
  hi: { nativeName: "हिंदी" },
  // mrt: { nativeName: "मराठी" },
  // grt: { nativeName: "ગુજરાતી" },
  // bgl: { nativeName: "বাংলা" },
  // tlg: { nativeName: "తెలుగు" },
  // tml: { nativeName: "தமிழ்" },
  // mlym: { nativeName: "മലയാളം" },
  // knd: { nativeName: "ಕನ್ನಡ" },
  en: { nativeName: "English" },
};

const Logo = ({adSoptData, locationPath}) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="logo-header">
      <div className="railtel-div">
          <img src={Imgs.railtelLogo} alt="" className="logo-railtel" />
        </div>
        <div className="bpcl-div">
          <img src={Imgs.BPCL_LOGO} alt="" className="logo-bpcl" />
        </div>
       
        <div className="nurebharat-div">
          <img src={Imgs.nuReBharat} alt="" className="logo-nureBharat" />
        </div>
      </div>
      {/* <div className="first-ad-div">
           <div className="langs-ad-bar1">
          <div className="choose-lang">{t("description.part3")}</div>
          <div className="dropdown">
            <DropdownButton id="dropdown-item-button" title={t('description.lang')}>
              {Object.keys(lngs).map((lng) => (
                <div>
                  {t('description.lang') === lngs[lng].nativeName ? (
                    <Dropdown.Item
                      value={lngs[lng].nativeName}
                      key={lng}
                      className="clr-drop"
                      onClick={() => {
                        // setValue(lngs[lng].nativeName);
                        i18n.changeLanguage(lng);
                      }}
                      type="submit"
                    >
                      <span>{lngs[lng].nativeName}</span>
                      <MdOutlineCheckCircleOutline className="circle-tick1"></MdOutlineCheckCircleOutline>
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item
                      value={lngs[lng].nativeName}
                      onClick={() => {
                        // setValue(lngs[lng].nativeName);
                        i18n.changeLanguage(lng);
                      }}
                    >
                      <span>{lngs[lng].nativeName}</span>
                      <MdOutlineCheckCircleOutline className="circle-tick"></MdOutlineCheckCircleOutline>
                    </Dropdown.Item>
                  )}
                </div>
              ))}

              
            </DropdownButton>
          </div>
        </div>
        </div> */}
      {/* <div className="sec-ad-header">
        
      </div> */}
      <div className="lang-ad-bar">
        <InsAdSopt adSoptData={adSoptData} locationPath={locationPath}/> 
      </div> 
    </>
  );
};

export default Logo;
