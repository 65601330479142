import React, { useState,useRef,lazy,useEffect } from "react";
import "../../src/main.css";
import axios from 'axios';
import { Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "video-react/dist/video-react.css";
import { BaseURL,localhostURL } from "../common/baseUrl";
import ReCAPTCHA from "react-google-recaptcha";
const LogoComponent = lazy(() => import('./logoComponent'));
const FooterComponent = lazy(() => import('./footer'));

const UserFeedback = ({stateCode}) => {
  const { t } = useTranslation();
  const [mobileNumber, setMobileNumber] = useState("");
  const [comments,setComments] = useState("");
  const [userName,setUserName] = useState("");
  const [userEmail,setUserEmail] = useState("");
  const [states,setStates] = useState([])
  const [stationCode,setStationCode] = useState([]);
  const [categories,setCategories] = useState("");
  const [paymentRef,setPaymentRef] = useState("");
  const [disabled,setDisable] =useState(true);
  const [validated, setValidated] = useState(false);
  const [hasError,setHasError]= useState(false);
  const [selectedState, setSelectedState] = useState('');
  const [stationName, setStationName] = useState('');
  const [stations, setStations] = useState([]);
  const navigate = useNavigate();
  const feedbackReqBody = {
    station_code:stationName,
    state:selectedState,
    name: "",
    email: userEmail,
    mobile_no: mobileNumber,
    category:categories,
    payment_ref_num:"",
    comment: comments,
    country_code:"",
  };
const captchaRef = useRef();
  const userFeedback = async () => {
    await fetch(`${localhostURL}/api/v1/radius/userfeedback`, {
      method: "POST",
      body: JSON.stringify(feedbackReqBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if(data.status == "OK"){
          navigate("/");
        }else{
          //alert(data.msg)
        }
      })
      .catch((err) => {
        console.log(err.messsage);
      });
  };

  useEffect(() => {
    const fetchStations = async () => {
      try {
        const response = await axios.get(
          `${localhostURL}/api/v1/ext/state`
        );
        setStates(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchStations();
  }, []);

  useEffect(() => {
    const fetchStations = async () => {
      try {
        const response = await axios.get(
          `${localhostURL}/api/v1/ext/station/${selectedState}`
        );
        setStationCode(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchStations();
  }, [selectedState]);
  
  const handleSubmit = (event) => {
if(mobileNumber.length >= 10 && userEmail.length >=1 && 
  categories.length !=0 && selectedState.length != 0 && stationName.length !=0){
  setHasError(false)
  userFeedback();
}else{
  setHasError(true);
}
    
  };

  return (
    <div className="header-main">
      <LogoComponent />
      <div className="back-head">
          <span
            className="back-btn"
          >
          </span>
          {/* <span className="back-text">{t("description.usernamePlace")}</span> */}
          <span className="back-text">{t('description.feedback')}</span>
        </div>
      <div className="p-num1">
        <Form>
        
           <Form.Group className="email-text12" controlId="formBasicPassword"> 
            <Form.Label className="label-form2">{t('description.state')}*</Form.Label>
            <Form.Control as="select" custom 
            required
              placeholder={t('description.state')}
              value={selectedState}
              type="text"
              onChange={(e) => {
                setSelectedState(e.target.value);
              }}
             
            >
             <option value="">{t('description.stateSelection')}</option> 
             {states && states?.map((e, key) => {
            return (
              <option value={e.state_name} key={key}>
                {e.state_name}
              </option>
            );
          })}
              </Form.Control>
              {hasError == true  && (
                          <div className="error-style">
                          {t('description.validStatefield')}
                          </div>
                        )}
           </Form.Group> 
           <Form.Group className="first-name-text" controlId="formBasicPassword"> 
            <Form.Label className="label-form2">{t('description.sCode')}*</Form.Label>
            <Form.Control as="select" custom 
            required
              placeholder={t('description.sCode')}
              value={stationName}
              type="text"
              onChange={(e) => {
                setStationName(e.target.value);
              }}
            >
             <option value="">{t('description.sCode')}</option> 
             {stationCode && stationCode?.map((e, key) => {
            return (
              <option value={e.station_name} key={key}>
                {e.station_name}
              </option>
            );
          })}
           </Form.Control>
           {hasError == true  && (
                          <div className="error-style">
                          {t('description.validScodefield')}
                          </div>
                        )}
           </Form.Group> 
           
          <Form.Group className="email-text12" controlId="formBasicEmail">
            <Form.Label className="label-form2">{t('description.email')}*</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('description.email')}
              value={userEmail}
              onChange={(e) => {
                setUserEmail(e.target.value);
              }}
            />
             {hasError == true  && (
                          <div className="error-style">
                          {t('description.validateEmail')}
                          </div>
                        )}
          </Form.Group>
             <Form.Group className="email-text12" controlId="formBasicPassword"> 
            <Form.Label className="label-form2">{t('description.phonePlace')}*</Form.Label>
            <Form.Control
            required
              placeholder={t('description.phonePlace')}
              value={mobileNumber}
              type="number"
              onChange={(e) => {
                setMobileNumber(e.target.value);
              }}
            />
             {hasError == true  && (
                          <div className="error-style">
                          {t('description.validNumber')}
                          </div>
                        )}
             </Form.Group>
              <Form.Group className="email-text12" controlId="formBasicPassword"> 
            <Form.Label className="label-form2">{t('description.category')}*</Form.Label>
            <Form.Control as="select" custom 
            required
              placeholder={t('description.category')}
              value={categories}
              type="text"
              onChange={(e) => {
                setCategories(e.target.value);
              }}
              >
              <option value="">{t('description.SelecteOption')}</option>
               <option value="debitIssue">{t('description.feedbackSelect1')}</option>
               <option value="internetIssue">{t('description.feedbackSelect2')}</option>
               <option value="pageIssue">{t('description.feedbackSelect3')}</option>
               <option value="otpIssue">{t('description.feedbackSelect4')}</option>
               <option value="slowIssue">{t('description.feedbackSelect5')}</option>
               </Form.Control>
               {hasError == true  && (
                          <div className="error-style">
                          {t('description.validateComplain')}
                          </div>
                        )}
           </Form.Group> 
             <Form.Group className="email-text12" controlId="formBasicEmail">
            <Form.Label className="label-form2">{t('description.complaint')}</Form.Label>
            <textarea
            className="commentsArea"
              type="text"
              placeholder={t('description.complaint')}
              value={comments}
              onChange={(e) => {
                setComments(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="email-text12" controlId="formBasicEmail">
          <ReCAPTCHA
              sitekey="6LcVZ6YlAAAAABeLRYy9uk2hQ_WBqMz8LAzxx4LD"
              ref={captchaRef}
                 />
          </Form.Group> 
        </Form>
        <Button
          className="enter-btn"
          onClick={() => {
            handleSubmit();
          }}
        >
         {t('description.submit')}
        </Button>
      </div>
      {/* <div className="video-ad">
      <PrimaryAd></PrimaryAd>
      </div> */}
      <div style={{ marginTop: "70px" }}>
        <FooterComponent />
      </div>
    </div>
  );
};

export default UserFeedback;
