import React, { useState,  lazy, useContext, useEffect } from "react";
import "../../src/main.css";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaWifi } from "react-icons/fa";
import { AiOutlineArrowRight } from "react-icons/ai";
import {isAndroid,isIOS,deviceDetect,mobileModel} from "react-device-detect";
import { Player, ControlBar, PlayToggle } from "video-react";
import "video-react/dist/video-react.css";
import {InsAdSopt} from './ad3i'
import { AdContext, AdIdContext,LocationData,userData } from "../components/adManagerContext";
import Imgs from '../common/imgImports';
import { Linking } from 'react-native';
import { LandingUrls } from "../common/randomSites";
import GoogleAd from "../components/adsense";
import { constants } from "../common/baseUrl";
const LogoComponent = lazy(() => import('./logoComponent'));
const FooterComponent = lazy(() => import('./footer'));

const HeaderComponent = () => {
  //const adLink = (isAndroid) ? 'intent://isroquiz.mygov.in#Intent;scheme=https;end' : 'https://isroquiz.mygov.in';
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // eslint-disable-next-line
  const [isMobileApp, setIsMobileApp] = useState(true);
  // eslint-disable-next-line
  const {addManager, setAddManager} = useContext(AdContext);
  const [ad1, setAd1] = useState(addManager?.find(itm => itm.ad_container=="CP_LandingTop"));
  const [ad2, setAd2] = useState(addManager?.find(itm => itm.ad_container=="CP_LandingCenter"));
  const [ad3, setAd3] = useState(addManager?.find(itm => itm.ad_container=="CP_LandingBottom"));
  // eslint-disable-next-line
  const {locationPath, bunConfig} = useContext(AdIdContext); 
  const configTimeout = bunConfig[0]?.CP_Landing;
  const [seconds, setSeconds] = useState(configTimeout);
  const androidVersion=parseInt(deviceDetect().osVersion);
  console.log("Android Version",androidVersion);
	useEffect(() => {
		const interval = setInterval(() => {
			setSeconds(seconds=> seconds-1)
		}, 1000);
		if(seconds < 0){
			clearInterval(interval)
		}
	}, [configTimeout]);

  useEffect(() =>{
    setAd1(addManager?.find(itm => itm?.ad_container === "CP_LandingTop"));
    setAd2(addManager?.find(itm => itm?.ad_container === "CP_LandingCenter"));
    setAd3(addManager?.find(itm => itm?.ad_container === "CP_LandingBottom"));
  },[addManager])
 
  // const linkingUrl =homeUrl;
  // const openIntentURL = async (url) => {
  //   try {
  //     const supported = await Linking.canOpenURL(url);
  
  //     if (supported) {
  //       await Linking.openURL(url);
  //     } else {
  //       console.log(`Cannot open URL: ${url}`);
  //     }
  //   } catch (error) {
  //     console.error('An error occurred while opening the URL:', error);
  //   }
  // };

  const openLinking =()=>{
    navigate("/phoneNumber");
  // if(isAndroid && isMobileApp === true && androidVersion > 10){
   // window.location.href="intent://cptg.piponet.in/phoneNumber#Intent;scheme=https;package=com.android.chrome;end";
    //window.location.href = "intent://cptg.piponet.in:phoneNumber#Intent;scheme=http;package=com.android.chrome;end";
  //let localUrl='intent:https://testcpt.piponet.in/phoneNumber#Intent;scheme=http;package=com.android.chrome;end';
    //window.open(localUrl,'_blank');
      //navigate("/phoneNumber");
  //  }
  //   else{
  //     navigate("/phoneNumber");
  //   }
         
  }
  // const appAvailable = () => {
  //   if (isAndroid) {
  //     const mac_Adress= window.localStorage.getItem("userMac");
  //     const user_ip= window.localStorage.getItem("userIp");
  //     const nas_id= window.localStorage.getItem("nasId");
  //     const ses_id= window.localStorage.getItem("sessionId");
  //     const nas_portid= window.localStorage.getItem("nasPortId");
  //     const aaa_Url= window.localStorage.getItem("aaaUrl");
  //       const url ="pipo://com.infotech3i.nbna?userAddr="+user_ip+"&nasIp="+nas_id+"&userMac="+mac_Adress+"&sessionId="+ses_id+"&nasPortId="+nas_portid+"&AAAUrl="+aaa_Url+"/";
  //         if (url){
  //           setIsMobileApp(true);
  //           window.location.replace(url);
  //         }else{
  //           setIsMobileApp(false);
  //         }
  //     } else if (isIOS) {
  //       window.location.replace("https://apps.apple.com/us/app/instagram/id389801252");
  //     } else {
  //       setIsMobileApp(false);
  //     }
  // }

  // const connectToApp = async () => {
  //   appAvailable();
  // }

  return (
    <div className="header-main">
      <LogoComponent adSoptData={ad1} locationPath={locationPath} />
      {/* enter phone number vd: 72622a54 */}
      <div className="video-ad">
        <InsAdSopt adSoptData={ad2} locationPath={locationPath}/>  
        {/* <GoogleAd slot={constants.CP_Landing_Billboard_Banner} googleAdId={constants.googleAdId_ext}/>      */}
      </div>
      <div className="header-main-text">
        <span style={{ cursor: "default" }}>
          {t("description.welcomeText")}
        </span>
      </div>
      <div className="wifi-logo-header">
        <FaWifi className="fawifi"></FaWifi>
      </div>
      <div className="terms-text">
        {t("description.termsText")}{" "}
        <span
          onClick={handleShow}
          className="click-term"
          style={{ cursor: "pointer" }}
        >
          {t("description.termsClick")}
        </span>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div>
            <span className="header-term">{t("description.aboutUs")}</span>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <span className="body-term">{t("description.aboutUsText")}</span>
          </div>
          <div>
            <span className="header-term">{t("description.contact")}</span>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <span className="body-term">{t("description.contactNo")}</span>
          </div>

          <div>
            <span className="header-term">{t("description.termsClick")}</span>
          </div>
          <div style={{ marginBottom: "60px" }}>
            <span className="body-term">
              {t("description.modalFooterText")}
            </span>
          </div>
        </Modal.Body>
      </Modal>
      <div className="btn-header">
        <Button
          variant="primary"
          className="wifi-btn"
          onClick={() => openLinking()}
          disabled={seconds > 0}
        >
           {isAndroid && isMobileApp === true || isIOS && isMobileApp === true ? (
            <span>{t("description.connectBrowser")} </span>)
            
            : (<span>{t("description.connect")} </span>
            )}
            {seconds > 0  ?  <>
              <div className="spinner-grow"  role="status">...</div>
            <>{seconds}</>
          </> : null}

          <AiOutlineArrowRight className="arrow_right"></AiOutlineArrowRight>
          <div className="line-heights"><span className="Free-wife-ad">({t("description.FreewifiBrowser")})</span></div>
        </Button>
        
      </div>
        <div className="bottom-ads-land mx-auto">
        {/* <GoogleAd slot={constants.CP_Landing_Billboard_Banner} googleAdId={constants.googleAdId_ext}/> */}
       
        {/* <GoogleAd slot={constants.CP_Landing_Billboard_Banner} googleAdId={constants.googleAdId_ext}/> */}
   
         <InsAdSopt adSoptData={ad3} locationPath={locationPath}/>  
        {/* <GoogleAd slot={constants.CP_Landing_Billboard_Banner} googleAdId={constants.googleAdId_ext}/> */}
        {/* <a href={adLink}><img class="img-fluid" src={Imgs.chandrayaan}/></a> */}
      </div>

      <div style={{ marginTop: "20px" }}>
        <FooterComponent />
      </div>
    </div>
  );
};

export default HeaderComponent;
