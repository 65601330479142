import React, {lazy, useContext, useEffect, useState } from "react";
import "../../src/main.css";
import { useTranslation } from "react-i18next";
import "video-react/dist/video-react.css";
import { FaWifi } from "react-icons/fa";
import {AdContext,AdIdContext} from './adManagerContext';
import Logo from "./logoComponent";
import {InsAdSopt} from './ad3i'; 
import { userVisit, setSessionFn } from '../common/commanfn';

const LogoComponent = lazy(() => import('./logoComponent'));
const FooterComponent = lazy(() => import('./footer'));



const WifiConnected = () => {
  // eslint-disable-next-line
  const {addManager, setAddManager} = useContext(AdContext);
  const [ad1, setAd1] = useState(addManager?.find(itm => itm.ad_container==="CP_ConnectedTop"));
  const [ad2, setAd2] = useState(addManager?.find(itm => itm.ad_container==="CP_ConnectedCenter"));
  const [ad3, setAd3] = useState(addManager?.find(itm => itm.ad_container==="CP_ConnectedBottom"));
  const [newsurl,setNewsUrl] = useState([]);
  const [state,setState] = useState([]);
  // eslint-disable-next-line
  const {locationPath, bunConfig} = useContext(AdIdContext); 
  const configTimeout = bunConfig[0]?.CP_Connected;
  const [seconds, setSeconds] = useState(configTimeout)
	useEffect(() => {
		const interval = setInterval(() => {
			setSeconds(seconds=> seconds-1)
		}, 1000);
		if(seconds < 0){
			clearInterval(interval)
		}
	}, [configTimeout]);

  useEffect(() =>{
    setAd1(addManager?.find(itm => itm?.ad_container === "CP_ConnectedTop"));
    setAd2(addManager?.find(itm => itm?.ad_container === "CP_ConnectedCenter"));
    setAd3(addManager?.find(itm => itm?.ad_container === "CP_ConnectedBottom"));
  },[addManager])
  useEffect(() => {
    const script = document.createElement('script');
    //const script1 = document.createElement('script');
    script.src = "https://jsc.mgid.com/p/i/piponet.in.1500777.js";
    //script1.src = "https://jsc.mgid.com/p/i/piponet.in.1500778.js";
    script.async = true;
    //script1.async = true;
   document.body.appendChild(script);
   // document.body.appendChild(script1);
    return () => {
      document.body.removeChild(script);
      //document.body.removeChild(script1);
    }
  }, []);
  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src = "https://jsc.mgid.com/p/i/piponet.in.1500778.js";
    script1.async = true;
    document.body.appendChild(script1);
    return () => {
      document.body.removeChild(script1);
    }
  }, []);

  useEffect(() => {
   
    fetch(`./${process.env.REACT_APP_NEWS_FILE}`)
    .then(res => res.json())
    .then
    (data => { setNewsUrl(data)
    console.log("State Data------",data);
    })
    .catch(e => 
      console.log("Env of add manager is missing ", e));
  }, []);

  useEffect(() => {
   
    setTimeout(() => {
      
      console.log("Map Data",locationPath);
      if(newsurl.length >0){
        let linkUrl= newsurl[0].url;
        const urlObj= newsurl?.find(d => d.state == locationPath?.state);
         linkUrl=(urlObj ? urlObj.url : linkUrl)
         window.open(linkUrl,'_blank');
      }
    }, "12000");
  }, [newsurl]);

  useEffect(() => { 
    setSessionFn();
    userVisit({wifi_connected: 1});
   },[]);

  const { t } = useTranslation();
  return (
    <div className="header-main">
      <Logo adSoptData={ad1} locationPath={locationPath}/>
      <div className="video-ad">
      <InsAdSopt adSoptData={ad2} locationPath={locationPath}/>
      </div>
      <div>
      <FaWifi className="wifi-connect-icon"></FaWifi>
      </div>
      <div>
        <span className="wifi-connect">{t('description.wifiConnect')}</span>
      </div>
      <div>
        <span className="free-wifi-text">{t('description.wifiConnect1')}</span>
      </div>
      <div className="bottom-ads2 mx-auto">
      <InsAdSopt adSoptData={ad3} locationPath={locationPath}/>
      </div>
      {/* <iframe width="340" height="315" src=""https://www.youtube.com/embed/aLONwnlBB-g?rel=0&modestbranding=1&autohide=1&mute=1&showinfo=0&controls=0&autoplay=1"" 
      title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; 
      encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
      <iframe width="340" height="315" src="https://www.youtube.com/embed/rtoU2XMEoHg?rel=0&modestbranding=1&autoplay=1"
      title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write;
      encrypted-media; gyroscope; picture-in-picture; web-share"  allowFullScreen>
         </iframe>
        
      <div>
            <div id="M903547ScriptRootC1500777">
        </div> 
      <script src="https://jsc.mgid.com/p/i/piponet.in.1500777.js" async>
          </script>
      <div id="M903547ScriptRootC1500778"></div> 
    <script src="https://jsc.mgid.com/p/i/piponet.in.1500778.js" async>
      </script> 
    
      </div>
      <FooterComponent />
     
    </div>
  );
};

export default WifiConnected;
