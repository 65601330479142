import React, { useState, lazy} from "react";
import "../../src/main.css";
import { useNavigate } from "react-router-dom";
import "video-react/dist/video-react.css";
import { useTranslation } from "react-i18next";
import { BiRupee } from "react-icons/bi";
import { Button, Form } from "react-bootstrap";
import { localhostURL,billdeskURL } from "../common/baseUrl";
import Cookies from 'js-cookie';
const LogoComponent = lazy(() => import('./logoComponent'));
const FooterComponent = lazy(() => import('./footer'));

const Payment = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [cusName,setCusName]= useState("");
  const [cusEmail,setCusEmail]= useState("");
  const [cusPayment,setCusPayment]= useState("");
  const [cusPincode,setCusPincode]=useState('');
  const [custGstin,setCustGstin] = useState('');
  const [cusAddress,setCusAdress] = useState('');
  const [hasError,setHasError]= useState(false);
  const [paramValue,setParamValue]= useState('');

  const dataList=JSON.parse(window.localStorage.getItem("listData"));
  const paymentData =JSON.parse(window.localStorage.getItem("dataPayment"));
  const customerData= JSON.parse(localStorage.getItem("cusData"));
  const reqBody=JSON.parse(window.localStorage.getItem("reqBody"));
  const number = JSON.parse(localStorage.getItem("phoneNumber"));
  const datalistPlan= JSON.parse(dataList);

  var urlParm="";
  const pgReqBody={
    filler_1: "NA",
    txn_amount:paymentData.price,
    //txn_amount:"1",
    bank_id :"NA",
    filler_2 :"NA",
    filler_3:"NA",
    currency_type:"INR",
    item_code :"",
    type_field_1 :"R",
    filler_4 :"NA",
    filler_5 :"NA",
    type_field_2 :"F",
    additional_info_1:number,
    additional_info_2:cusEmail,
    additional_info_3:cusAddress,
    additional_info_4:cusPincode,
    additional_info_5:"NA",
    additional_info_6:"NA",
    additional_info_7:"NA",
    checksum:"",
    response_status :"PENDING",
    transaction_ref_no :"",
    bank_ref_no :"",
    bank_merchant_id:"",
    txn_type :cusPayment,
    security_type:"",
    security_id :"",
    security_password:"",
    settlement_type:"",
    auth_status:"",
    error_status:"",
    error_desc:"", 
    reqBody : reqBody,
    custdata : customerData
  }
  var customerDetailsData= {
    firstname:cusName,
    email:cusEmail,
    pincode:cusPincode,
    address:cusAddress,
    gstin:custGstin,
  }
  window.localStorage.setItem("cusDeatilsData",JSON.stringify(customerDetailsData));
  const pgCreateOrder = async () => {
    await fetch(`${localhostURL}/api/v1/radius/createPGOrder`, {
      method: "POST",
      body: JSON.stringify(pgReqBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("send sms data", data.status);
        setParamValue(data.message);
        Cookies.set('checksum', paramValue, { expires: 10 });
        console.log("CALL BACK-----",urlParm);
       const dataMessage= window.sessionStorage.setItem("reqParam",data.message);
        return data.message;
      })
      .catch((err) => {
        console.log(err.messsage);
      });
  };
  
    const payBilldesk = async () => {
      if(cusName.length >= 1 && cusEmail.length > 1){
        setHasError(false);
        window.sessionStorage.removeItem("reqParam");
        const resp =  await pgCreateOrder();
        const dataMessages= window.sessionStorage.getItem("reqParam");
        console.log("redirect url------",dataMessages);
        const url = `${billdeskURL}?msg=`+ dataMessages;
        console.log("redirect url for resp------",resp);
        console.log("redirect url11------",url);
        window.location.href = url;
      } else{
          setHasError(true);
      }
    };

  return (
    <div className="header-main">
      <LogoComponent />

      <div className="payment-header">
        <span>
        {t('description.paymentAmount')} <BiRupee className="rupee"></BiRupee>{paymentData.price} 
        </span>{" "}
      </div>
     
      <div className="p-num1">
        <Form>
          <Form.Group className="first-name-text" controlId="usernamePlace">
            <Form.Label className="label-form2">{t('description.usernamePlace')}*</Form.Label>
            <Form.Control
              placeholder={t('description.usernamePlace')}
              value={cusName}
              type="text"
              onChange={(e) => {
                setCusName(e.target.value);
              }}
            />
             {hasError ==true  && (
                          <div className="error-style">
                          {t('description.ValidateUser')}
                          </div>
                        )}
          </Form.Group>
          <Form.Group className="email-text12" controlId="pincode">
            <Form.Label className="label-form2">{t('description.pincode')}</Form.Label>
            <Form.Control
              type="pincode"
              placeholder={t('description.pincode')}
              value={cusPincode}
              onChange={(e) => {
                setCusPincode(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="email-text12" controlId="Email">
            <Form.Label className="label-form2">{t('description.email')}*</Form.Label>
            <Form.Control
              placeholder={t('description.email')}
              value={cusEmail}
              type="email"
              onChange={(e) => {
                setCusEmail(e.target.value);
              }}
            />
             {hasError == true  && (
                <div className="error-style">
                {t('description.validateEmail')}
                </div>
              )}
          </Form.Group>
          <Form.Group className="email-text12" controlId="GSTIN">
            <Form.Label className="label-form2">{t('description.GSTIN')}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t('description.GSTIN')}
              value={custGstin}
              onChange={(e) => {
                setCustGstin(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="email-text12" controlId="Address">
            <Form.Label className="label-form2">{t('description.Address')}</Form.Label>
            <Form.Control as="textarea" custom
              type="text"
              placeholder={t('description.Address')}
              value={cusAddress}
              onChange={(e) => {
                setCusAdress(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="email-text12" controlId="Payment Gateway">
            <Form.Label className="label-form2">{t('description.PaymentGateway')}*</Form.Label>

            <Form.Control as="select" custom 
            value={cusPayment}
            onChange={(e) => {
                setCusPayment(e.target.value)}}>
              <option value="">{t('description.SelecteOption')}</option>
              <option value="billDesk" selected>BillDesk</option>
            </Form.Control>
          </Form.Group>
        </Form>
        <div className="payment-buttons">
        <Button
          variant="primary"
          className="cancel-payment"
          onClick={() => navigate("/selectPaidOptions")}
        >
            {t('description.cancel')}
        </Button>
        <Button
          variant="primary"
          className="wifi-paid1"
          onClick={() => payBilldesk()}
        >
          {t('description.pay')}
        </Button>
        </div>
      </div>
      <div>
        <FooterComponent />
      </div>
    </div>
  );
};

export default Payment;
