import React, { useState } from 'react';
import { Player, ControlBar, PlayToggle } from "video-react";

const DyPlayer = ({ src, metaData, placeholder }) => {
  const [error, setError] = useState(false);

  const handleImageError = () => {
    setError(true);
  };

  return (
    <>
      {error ? (
         <Player 
         autoPlay={true}
         muted={true}
         loop={true}
         allowfullscreen={false}
         playsInline={true}
         data-meta={metaData}
         src={placeholder}></Player>
      ) : (
        <Player 
         autoPlay={true}
         muted={true}
         loop={true}
         allowfullscreen={false}
         playsInline={true}
         data-meta={metaData}
         src={src} onError={handleImageError}></Player>
      )}
    </>
  );
};

export default DyPlayer;
