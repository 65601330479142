import React ,{useEffect} from "react";
import Imgs from "../common/imgImports";
import "../../src/main.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { MdOutlineCheckCircleOutline } from "react-icons/md";
const lngs = {
  hi: { nativeName: "हिंदी" },
  // mrt: { nativeName: "मराठी" },
  // grt: { nativeName: "ગુજરાતી" },
  // bgl: { nativeName: "বাংলা" },
  // tlg: { nativeName: "తెలుగు" },
  // tml: { nativeName: "தமிழ்" },
  // mlym: { nativeName: "മലയാളം" },
  // knd: { nativeName: "ಕನ್ನಡ" },
  en: { nativeName: "English" },
};

const Footer = () => {
  const { t,i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    var a, script = document.createElement("script"), f = window.frameElement;
    //const script1 = document.createElement('script');
    script.src = "https://scripts.cleverwebserver.com/54bb42353bb1b29246f8649c6406e1d2.js";
    script.async = true;
    script.id= "CleverCoreLoader82965";
    script.type = "text/javascript";
    script.setAttribute("data-target",window.name|| (f && f.getAttribute("id")));
    script.setAttribute("data-callback", "put-your-callback-function-here");
    script.setAttribute("data-callback-url-click", "put-your-click-macro-here");
    script.setAttribute("data-callback-url-view", "put-your-view-macro-here");
    try {
       a = window.parent.document.getElementsByTagName("script")[0] || document.getElementsByTagName("script")[0];
       //a= document.body.appendChild(script);;
    } catch (e) {
        a = false;
    }
    a || (a = document.getElementsByTagName("head")[0] || document.getElementsByTagName("body")[0]);
    a.parentNode.insertBefore(script, a);
  //document.body.appendChild(script);

    // return () => {
    //    document.body.removeChild(script);

    // }
  }, []);

  return (
    <><div>
      <hr className="hr-tg" />
      <div className="login-terms-text">

        <div>
          <img src={Imgs.logo3i} alt="" className="logo-3i" />
        </div>
        <div className="footer-text">
          {/* <div className="first-ad-div"> */}
          {/* <div className="langs-ad-bar1"> */}
          {t("description.part3")}
          {/* <span className="dropdown"> */}
          <DropdownButton id="dropdown-item-button" title={t('description.lang')}>
            {Object.keys(lngs).map((lng) => (
              <span>
                {t('description.lang') === lngs[lng].nativeName ? (
                  <Dropdown.Item
                    value={lngs[lng].nativeName}
                    key={lng}
                    className="clr-drop"
                    onClick={() => {
                      // setValue(lngs[lng].nativeName);
                      i18n.changeLanguage(lng);
                    } }
                    type="submit"
                  >
                    <span>{lngs[lng].nativeName}</span>
                    <MdOutlineCheckCircleOutline className="circle-tick1"></MdOutlineCheckCircleOutline>
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item
                    value={lngs[lng].nativeName}
                    onClick={() => {
                      // setValue(lngs[lng].nativeName);
                      i18n.changeLanguage(lng);
                    } }
                  >
                    <span>{lngs[lng].nativeName}</span>
                    <MdOutlineCheckCircleOutline className="circle-tick"></MdOutlineCheckCircleOutline>
                  </Dropdown.Item>
                )}
              </span>
            ))}


          </DropdownButton>
          {/* </span> */}
          {/* </div> */}
          {/* </div> */}
          <div>
            {t("description.feedback")}
            {" "}
            <a href="javascript:void(0)">
              <span className="click-foot"
                onClick={() => {
                  navigate("/submitFeedback");
                } }
              >
                {t("description.click")}

              </span></a>
          </div>
          <div>
            {t("description.ad")}
            {" "}
            <a href="javascript:void(0)">
              <span className="click-foot"
                onClick={() => {
                  navigate("/submitAdEnquiry");
                } }
              >
                {t("description.click")}
              </span></a>
          </div>
        </div>
      </div>
    </div>
      </>
  );
};

export default Footer;