import React, { useState, useContext, useEffect} from "react";
import "../../src/main.css";
import { Button} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BiRupee } from "react-icons/bi";
import Logo from "./logoComponent";
import { useTranslation } from "react-i18next";
import { FiArrowLeft } from "react-icons/fi";
import Footer from "./footer";
import {AdContext, AdIdContext} from './adManagerContext';
import {InsAdSopt} from './ad3i'; 

const PaidOptions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {addManager, setAddManager} = useContext(AdContext)
  const {adSoptIdhdr, setAdSoptIdhdr,locationPath} = useContext(AdIdContext); 
  const [ad1, setAd1] = useState(addManager?.find(itm => itm.ad_container=="CP_Paid_WifiTop"));
  const [ad2, setAd2] = useState(addManager?.find(itm => itm.ad_container=="CP_Paid_WifiCenter"));
  const [ad3, setAd3] = useState(addManager?.find(itm => itm.ad_container=="CP_Paid_WifiBottom"));
  useEffect(() =>{
    setAd1(addManager?.find(itm => itm?.ad_container === "CP_Paid_WifiTop"));
    setAd2(addManager?.find(itm => itm?.ad_container === "CP_Paid_WifiCenter"));
    setAd3(addManager?.find(itm => itm?.ad_container === "CP_Paid_WifiBottom"));
  },[addManager])
  const [dataObj, setDataObj] = useState([]);
  const dataList=JSON.parse(window.localStorage.getItem("listData"));
  var datalistobj= JSON.parse(dataList);
    const selectedData = (id,price,index,netprice,date,ctn,accountNo) =>{
    const paymentData={
      id:id,
      price:price,
      index:index,
      netPrice:netprice,
      data:date,
      ctn:ctn,
      accountNo:accountNo
    }
    window.localStorage.setItem("dataPayment",JSON.stringify(paymentData));
    navigate("/Payment")
  }
  //  addManager={addManager} displayAdId={displayAdId}
  return (
    <div className="header-main">
      <Logo adSoptData={ad1} locationPath={locationPath}/>
      {/* enter phone number */}
      <div className="video-ad">
      <InsAdSopt adSoptData={ad2} locationPath={locationPath}/> 
      </div>
      <div>
        <div className="back-head">
          <span
            className="back-btn"
            onClick={() => {
              navigate("/selectWifi");
            }}
          >
            <FiArrowLeft></FiArrowLeft>
          </span>
          <span className="back-text">{t("description.paidOptionText")}</span>
        </div>
        <div className="p-num">
         {datalistobj && datalistobj.map((list, index) => ( 
          <div className="card-wifi" key={list.id}>
            <div className="card-body1">
              <h3 className="first-span">
                 <BiRupee></BiRupee>{list.mrpcpprice}
              </h3>
              <h6 className="second-span">{list.descr}</h6>
            </div>
            <Button
              variant="primary"
              className="buy-btn"
              value={list.id}
              // onClick={() => navigate("/Payment")}actno

              onClick={() => selectedData(list.id,list.mrpcpprice,index,list.netprice,list.crtdate,list.ctn,list.actno)}
            >
             {t("description.buy")}
            </Button>
          </div>
         ))} 
         
         
        </div>
      </div>
      <div className="bottom-ads2 mx-auto">
      <InsAdSopt adSoptData={ad3} locationPath={locationPath}/> 
      </div>
      {/* footer link */}
      <div style={{marginTop:"32px"}}>
      <Footer></Footer>
      </div>
      
    </div>
  );
};

export default PaidOptions;
